import React, { useEffect, useState } from 'react';
import { Avatar, List, ListItem } from '@mui/material';
import { useUserContext } from '../../../../contexts/userContext';
import accountImage from '../../../../images/default_account.png';
// import Settings from "../settings/Settings";
import moment from 'moment';

const ConversationDetails = ({ convoSid, convo, participants }) => {
  const { user, setUser } = useUserContext();
  const [participant, setParticipant] = useState(null);
  let secondParticipantId = convo.friendlyName.replace('_', '').replace(user.id, '');

  useEffect(() => {
    if (participants[convoSid]) {
      let convParticipant = {};
      participants[convoSid].map((part) => {
        if (part.identity == user.id) {
          convParticipant = part;
        }
      });
      setParticipant({ ...convo[secondParticipantId], ...convParticipant });
    }
  }, [participants, convo[secondParticipantId]]);

  return (
    <List sx={{ padding: 0 }}>
      {participant && (
        <ListItem className={'message-box-header'}>
          <div className={'chat-avatar'} sx={{ padding: 0 }}>
            {participant.imageUrl ? <Avatar alt='Remy Sharp' src={participant.imageUrl} /> : <Avatar alt='Remy Sharp' src={accountImage} />}
          </div>
          <div className={'chat-user'}>
            <div className={'chat-user-line-one'}>
              <div className={'chat-user-name'}>{participant.fullName}</div>
              <div className={'chat-message-date'}>
                {participant.state && participant.state.lastReadTimestamp ? moment(participant.state.lastReadTimestamp, 'HH:mm').fromNow() : ''}
              </div>
            </div>
          </div>
        </ListItem>
      )}
      {/*<Settings convo={props.convo} participants={props.participants} />*/}
    </List>
  );
};

export default ConversationDetails;
