import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import './styles.css';
import Grid from '@mui/material/Grid';

function GuestPageWrapper() {
  const [background, setBackground] = useState();

  return (
    <Grid container component='main' className={'guest-page'} justifyContent='center'>
      <Grid item xs={8} sm={8} md={5} lg={4} xl={4} className={'content'}>
        <Outlet context={[background, setBackground]} />
      </Grid>
    </Grid>
  );
}

export default GuestPageWrapper;
