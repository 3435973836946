import { get, put, downloadGet } from '../utils/api';
import { BASE_URL } from '../utils/consts';
import moment from 'moment';

export function getHookups(params) {
  return get(`${BASE_URL}/hookup-admin/all-hookups`, params);
}

export function getHookup(params) {
  return get(`${BASE_URL}/hookup-admin/hookup/${params.id}`, params);
}

export function updateHookup(params) {
  return put(`${BASE_URL}/hookup-admin/hookup`, params, true);
}

export function exportHookups(params) {
  const formattedDate = moment(new Date()).format("YYYY-MM-DD-HHmm");
  const csvFileName = `Hookups-Report-${formattedDate}.csv`;
  return downloadGet(`${BASE_URL}/hookup-admin/hookup/export`, params, csvFileName);
}
