import { get } from '../utils/api';
import { BASE_URL } from '../utils/consts';

export function getJobPositions() {
  return get(`${BASE_URL}/job-positions`);
}

export function getGoals() {
  return get(`${BASE_URL}/goals`);
}

export function getSeniorityLevels() {
  return get(`${BASE_URL}/seniority-levels`);
}

export function getCountries() {
  return get(`${BASE_URL}/countries`);
}

export function getCompanySize() {
  return get(`${BASE_URL}/company-sizes`);
}

export function getYearsOfExperience() {
  return get(`${BASE_URL}/years-of-experience`);
}
