import { ENVIRONMENTS } from '../config/environments';

export const LANGUAGES = [
  {
    lang: 'en',
    text: 'English',
    icon: 'flag-en'
  }
];

export const BASE_URL = ENVIRONMENTS.BACKEND_URL;

export const minLengthPassword = 8;
export const maxLengthPassword = 250;
export const minLengthUsername = 4;
export const maxLengthUsername = 100;

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const USER_MANDATORY_FIELDS = ['firstName', 'lastName', 'phoneNumber', 'jobPosition', 'linkedInUrl', 'goals'];

/**
 *
 * CHAT Constants
 *
 */

export const NOTIFICATION_LEVEL = {
  DEFAULT: 'default',
  MUTED: 'muted'
};

export const MessageStatus = {
  Sending: 'Sending',
  Sent: 'Sent',
  Delivered: 'Delivered',
  Failed: 'Failed',
  None: 'none (incoming)',
  Read: 'Read'
};

export const yesNoOptionsEN = [
  { id: true, name: 'yes' },
  { id: false, name: 'no' }
];

export const MAX_FILE_SIZE = 52428800;
export const COPY_SUCCESS_MESSAGE = 'Message copied.';
export const UNEXPECTED_ERROR_MESSAGE = 'Something went wrong. Please try again.';
export const CONNECTION_ERROR_MESSAGE = 'No internet connection.';
export const NOTIFICATION_TIMEOUT = 4000;
export const ERROR_MODAL_MESSAGES = {
  ADD_PARTICIPANT: {
    title: 'Unable to add participant',
    description: 'You don’t have permission to add participants to the conversation'
  },
  CHANGE_CONVERSATION_NAME: {
    title: 'Unable to save Conversation name',
    description: 'Only creators of the Conversation can edit the Conversation name.'
  }
};
export const CONVERSATION_MESSAGES = {
  CREATED: 'Conversation created.',
  NAME_CHANGED: 'Conversation name changed.',
  LEFT: 'You left the conversation.'
};

export const PARTICIPANT_MESSAGES = {
  ADDED: 'Participant added.',
  REMOVED: 'Participant removed.'
};

export const CONVERSATION_PAGE_SIZE = 30;
