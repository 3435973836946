import React, { useContext, useState } from 'react';

const TypingDataContext = React.createContext();

export const useTypingDataContext = () => useContext(TypingDataContext);

export function TypingDataProvider({ children }) {
  const [typingData, setTypingDataToState] = useState([]);
  const [askToRegister, setAskToRegister] = useState(true);

  return (
    <TypingDataContext.Provider
      value={{
        typingData,
        setTypingData: (typingData) => {
          setTypingDataToState(typingData);
        }
      }}
    >
      {children}
    </TypingDataContext.Provider>
  );
}
