import React, { useEffect, useRef, useState } from 'react';
import './styles.css';
import TextField from '../Form/TextField';
import { Alert, AlertTitle, Dialog, DialogActions, DialogContent, FormGroup, Grid, Input } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useLanguageContext } from '../../contexts/languageContext';
import accountImage from '../../images/default_account.png';
import { Field, Form } from 'react-final-form';
import { composeValidators, matchPattern, minLength, required } from '../../utils/form';
import { getCompanySize, getCountries, getGoals, getJobPositions, getSeniorityLevels, getYearsOfExperience } from '../../services/masterDataService';
import TextArea from '../Form/TextArea';
import { useUserContext } from '../../contexts/userContext';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { create, deletePhoto, deleteUser, getUserDetails, update, uploadPhoto } from '../../services/userService';
import Popup from '../Popup';
import FileUpload from '../FileUpload';
import { FORM_ERROR } from 'final-form';
import { CheckCircleOutline } from '@mui/icons-material';
import MultiSelectField from '../Form/MultiSelect';
import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import md5 from 'md5';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Error from '../Form/Error';
import Autocomplete from 'react-google-autocomplete';
import { ENVIRONMENTS } from '../../config/environments';

function ProfileForm() {
  const { gettext } = useLanguageContext();
  const [loading, setLoading] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [jobPositions, setJobPositions] = useState([]);
  const [seniorityLevels, setSeniorityLevels] = useState([]);
  const [yearsOfExperience, setYearsOfExperience] = useState([]);
  const [companySize, setCompanySize] = useState([]);
  const [countries, setCountries] = useState([]);
  const [goalsResponse, setGalsResponse] = useState([]);
  const [goalsSelected, setGoalsSelected] = useState([]);
  const [profileChanged, setProfileChanged] = useState(false);
  const [initVals, setInitVals] = useState(false);
  let { userId } = useParams();
  const [open, setOpen] = useState(false);

  const profileForm = useRef();

  const [filePopup, setFilePopup] = useState(undefined);

  const [newProfile, setNewProfile] = useState(undefined);

  const [currentUser, setCurrentUser] = useState({});

  const { user, setUser } = useUserContext();

  const [userLocation, setUserLocation] = useState();

  const location = useLocation();

  const validate = (values) => undefined;
  const [noGoalSelectedError, setNoGoalSelectedError] = useState(false);

  const getMasterData = () => {
    getJobPositions()
      .then((result) => {
        let jobs = result.data.map((job) => {
          return {
            id: job,
            name: gettext('APP.USERS.JOBS.' + job)
          };
        });
        setJobPositions(jobs);
        getGoals().then((result) => {
          setGalsResponse(result.data);
          let goals = [];
          if (currentUser.goals) {
            currentUser.goals.map((goal) => {
              goals.push(goal);
            });
          }

          //TODO refactor
          setGoalsSelected(goals);
          getSeniorityLevels().then((result) => {
            result.data.unshift('');
            setSeniorityLevels(result.data);
            getCountries().then((result) => {
              result.data.unshift('');
              setCountries(result.data);
              getYearsOfExperience().then((result) => {
                result.data.unshift('');
                setYearsOfExperience(result.data);
                getCompanySize().then((result) => {
                  result.data.unshift('');
                  setCompanySize(result.data);
                  setLoading(false);
                  setInitVals({
                    ...currentUser,
                    ...{
                      seniorityLevel: currentUser.seniorityLevel
                        ? {
                            id: currentUser.seniorityLevel,
                            name: gettext('APP.USER.SENIORITY_LEVEL.' + currentUser.seniorityLevel)
                          }
                        : '',
                      yearsOfExperience: currentUser.yearsOfExperience
                        ? {
                            id: currentUser.yearsOfExperience,
                            name: gettext('APP.USER.YEARS_EXPERIENCE.' + currentUser.yearsOfExperience)
                          }
                        : '',
                      companySize: currentUser.companySize
                        ? {
                            id: currentUser.companySize,
                            name: gettext('APP.USER.COMPANY_SIZE.' + currentUser.companySize)
                          }
                        : '',
                      jobPosition: currentUser.jobPosition
                        ? {
                            id: currentUser.jobPosition,
                            name: gettext('APP.USERS.JOBS.' + currentUser.jobPosition)
                          }
                        : '',
                      country: currentUser.countryCode
                        ? {
                            id: currentUser.countryCode,
                            name: gettext('APP.USERS.COUNTRIES.' + currentUser.countryCode)
                          }
                        : ''
                    }
                  });
                });
              });
            });
          });
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getMasterData();
  }, [currentUser]);

  useEffect(() => {
    setNewProfile(location.pathname === '/users/create');
    if (location.pathname === '/users/create') {
      getMasterData();
    } else {
      getUserDetails(userId).then((result) => {
        setCurrentUser(result.data);
        setUserLocation(result.data.location);
      });
    }
  }, [userId]);

  const handleGoals = (goal) => {
    if (goal.target.checked === true) {
      setNoGoalSelectedError(false);
      setGoalsSelected([...goalsSelected, goal.target.value]);
    } else {
      const uncheckedGoals = [...goalsSelected];
      const removeIndex = uncheckedGoals.indexOf(goal.target.value);
      uncheckedGoals.splice(removeIndex, 1);
      setGoalsSelected(uncheckedGoals);
    }
  };
  const uploadPhotoHandler = () => {
    setFilePopup(true);
  };

  const deletePhotoHandler = () => {
    deletePhoto(userId).then((response) => {
      setCurrentUser({ ...currentUser, ...{ imageUrl: response.data.imageUrl } });
    });
  };

  const handleUserSubmit = (formValues, userId) => {
    if (newProfile) {
      formValues.password = md5(moment().date().toString()) + 'Aa1!';
      formValues.confirmPassword = formValues.password;
      return create(formValues).then((result) => {
        window.location.href = '/users/' + result.data.id + '/edit';
      });
    }
    return update(formValues, userId);
  };

  const onSubmit = (params) => {
    let formValues = params;
    if (goalsSelected.length > 0) {
      setNoGoalSelectedError(false);
      formValues.goals = goalsSelected;
    } else {
      setNoGoalSelectedError(true);
      return;
    }
    if (params.seniorityLevel) {
      formValues.seniorityLevel = params.seniorityLevel.id;
    }
    if (params.companySize) {
      formValues.companySize = params.companySize.id;
    }
    if (params.yearsOfExperience) {
      formValues.yearsOfExperience = params.yearsOfExperience.id;
    }
    if (params.jobPosition) {
      formValues.jobPosition = params.jobPosition.id;
    }
    if (params.country) {
      formValues.countryCode = params.country.id;
    }
    formValues.location = userLocation;
    return handleUserSubmit(formValues, userId)
      .then((response) => {
        setProfileChanged(true);
        window.scrollTo(0, 0);
        getUserDetails(userId).then((res) => {
          setCurrentUser({ ...currentUser, ...res.data });
          setUserLocation(res.data.location);
          setInitVals({
            ...res.data,
            ...{
              seniorityLevel: res.data.seniorityLevel
                ? {
                    id: res.data.seniorityLevel,
                    name: gettext('APP.USER.SENIORITY_LEVEL.' + res.data.seniorityLevel)
                  }
                : '',
              companySize: res.data.companySize
                ? {
                    id: res.data.companySize,
                    name: gettext('APP.USER.COMPANY_SIZE.' + res.data.companySize)
                  }
                : '',
              yearsOfExperience: res.data.yearsOfExperience
                ? {
                    id: res.data.yearsOfExperience,
                    name: gettext('APP.USER.YEARS_EXPERIENCE.' + res.data.yearsOfExperience)
                  }
                : '',
              jobPosition: res.data.jobPosition
                ? {
                    id: res.data.jobPosition,
                    name: gettext('APP.USERS.JOBS.' + res.data.jobPosition)
                  }
                : '',
              country: res.data.country
                ? {
                    id: res.data.country.id,
                    name: gettext('APP.USERS.COUNTRIES.' + res.data.country.name)
                  }
                : ''
            }
          });
        });
      })
      .catch((err) => {
        return Promise.resolve({ [FORM_ERROR]: err && err.response && err.response.data && err.response.data.code ? err.response.data.code : 'GENERAL' });
      });
  };

  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = (submitForm) => {
    setOpen(false);
    if (submitForm) onSubmitDelete();
  };

  const onSubmitDelete = () => {
    deleteUser(userId).then((result) => {
      window.location.href = '/users';
    });
  };
  const resetValues = () => {
    getUserDetails().then((res) => {
      let goals = [];
      res.data.goals.map((goal) => {
        goals.push(goal.code);
      });
      setGoalsSelected(goals);
      [...profileForm.current.elements].filter((element) => {
        element.value = res.data[element.name];
      });
      setUserLocation(res.data.location);
    });
  };

  return (
    <>
      <Dialog open={open} onClose={() => closeDialog(false)} aria-labelledby='responsive-dialog-title'>
        <DialogTitle id='responsive-dialog-title' className={'mangueira'}>
          {gettext('APP.USER.SETTINGS_WARNINGS.DELETE_USER_TITLE')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{gettext('APP.USER.SETTINGS_WARNINGS.DELETE_USER_TEXT')}</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', margin: '10px 0px' }}>
          <Grid className={'form-btns-container'}>
            <button type='button' className={'reset-btn profile-btns'} onClick={() => closeDialog(false)}>
              {gettext('APP.USERS.COMMON.BUTTON_CANCEL')}
            </button>
            <button type='button' className={'upload-btn profile-btns'} onClick={() => closeDialog(true)}>
              {gettext('APP.USERS.COMMON.BUTTON_DELETE')}
            </button>
          </Grid>
        </DialogActions>
      </Dialog>
      {filePopup && (
        <Popup open={filePopup} onClose={() => setFilePopup(false)} title={gettext('APP.USERS.PROFILE.UPLOAD_PHOTO_TITLE')}>
          <FileUpload
            fieldName='file'
            api={uploadPhoto}
            user={userId}
            onSuccess={(response) => {
              setFilePopup(false);
              setCurrentUser({ ...currentUser, ...{ imageUrl: response.imageUrl } });
            }}
            accept='image/png, image/gif, image/jpeg'
          />
        </Popup>
      )}
      <Grid container className='user-page-container'>
        {profileChanged && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Alert severity='success' icon={<CheckCircleOutline sx={{ fontSize: 50 }} />}>
              <AlertTitle>{gettext('APP.USER.SETTINGS_WARNINGS.PROFILE_UPDATE_TITLE')}</AlertTitle>
              {gettext('APP.USER.SETTINGS_WARNINGS.PROFILE_UPDATE_TEXT')}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <h1 className={'profile-title'}>{newProfile ? gettext('APP.USERS.PROFILE.CREATE_TITLE') : gettext('APP.USERS.PROFILE.TITLE')}</h1>
        </Grid>

        {!newProfile && (
          <Grid container>
            <Grid item xs={8} sm={8} md={8} lg={8} xl={8} className={'profile-image-wrapper'}>
              <p className={'profile-image-title'}>{gettext('APP.USERS.PROFILE.SUBTITLE')}</p>
              <Grid className={'profile-image-btns-wrapper'}>
                <Typography component='div' className={'profile-image'}>
                  {currentUser.imageUrl ? <img src={currentUser.imageUrl} alt='Profile' /> : <img src={accountImage} alt='Profile' />}
                </Typography>
                <button type='button' disabled={!currentUser.id} className={'upload-btn profile-btns'} onClick={uploadPhotoHandler}>
                  {gettext('APP.USERS.PROFILE.UPLOAD_PHOTO')}
                </button>
                <button type='button' disabled={!currentUser.id} className={'remove-btn profile-btns'} onClick={deletePhotoHandler}>
                  {gettext('APP.USERS.PROFILE.DELETE_PHOTO')}
                </button>
              </Grid>
              <p className={'help-text'}>{gettext('APP.USERS.PROFILE.IMAGE_FORMATS')}</p>
              <br />
            </Grid>
            {userId && (
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className={'text-right'}>
                <Grid>
                  <button type='button' className={'upload-btn profile-btns'} onClick={openDialog}>
                    {gettext('APP.USERS.COMMON.BUTTON_DELETE_USER')}
                  </button>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}

        <Grid className={'profile-form'} item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Form
            onSubmit={onSubmit}
            validate={validate}
            initialValues={initVals}
            render={({ handleSubmit, submitError, submitting }) => {
              return (
                <form onSubmit={handleSubmit} ref={profileForm}>
                  <Grid className={'form-inner-container'} spacing={1} container>
                    <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
                      <Field
                          component={TextField}
                          name='username'
                          id='username'
                          label={gettext('APP.USERS.REGISTER_FORM.EMAIL_LABEL')}
                          placeholder={gettext('APP.USERS.REGISTER_FORM.EMAIL_PLACEHOLDER')}
                          type='text'
                          validate={composeValidators(required, matchPattern('email', gettext('APP.USERS.REGISTER_FORM.EMAIL_RULES')))}
                          required
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
                      <Field
                        component={TextField}
                        name='firstName'
                        id='firstName'
                        label={gettext('APP.USERS.REGISTER_FORM.NAME_LABEL')}
                        placeholder={gettext('APP.USERS.REGISTER_FORM.NAME_PLACEHOLDER')}
                        type='text'
                        validate={composeValidators(required, minLength(1))}
                        required
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
                      <Field
                        component={TextField}
                        name='lastName'
                        id='lastName'
                        label={gettext('APP.USERS.REGISTER_FORM.LASTNAME_LABEL')}
                        placeholder={gettext('APP.USERS.REGISTER_FORM.LASTNAME_PLACEHOLDER')}
                        type='text'
                        validate={composeValidators(required, minLength(1))}
                        required
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
                      <Field
                        component={TextField}
                        name='phoneNumber'
                        id='phoneNumber'
                        label={gettext('APP.USERS.REGISTER_FORM.PHONE_LABEL')}
                        placeholder={gettext('APP.USERS.REGISTER_FORM.PHONE_PLACEHOLDER')}
                        type='text'
                        validate={composeValidators(matchPattern('phone', gettext('APP.USERS.REGISTER_FORM.PHONE_RULES'), true))}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
                      <div className='input-group' style={{ opacity: 1, position: 'relative' }}>
                        <label style={(!userLocation || !userLocation.name) && submitted ? { color: 'red' } : {}}>
                          {gettext('APP.USERS.REGISTER_FORM.LOCATION_LABEL')} *
                        </label>
                        <Input
                          className={'custumInput'}
                          required
                          style={(!userLocation || !userLocation.name) && submitted ? { border: '1px solid red' } : {}}
                          defaultValue={userLocation && userLocation.name}
                          inputComponent={({ inputRef, onFocus, onBlur, ...props }) => (
                            <Autocomplete
                              apiKey={ENVIRONMENTS.GOOGLE_API_KEY}
                              {...props}
                              language={'en'}
                              onChange={(e) => {
                                if (!e.target.value) setUserLocation({});
                              }}
                              onBlur={(e) => {
                                if (userLocation && e.target.value != userLocation.name) {
                                  e.target.value = userLocation.name;
                                }
                              }}
                              onPlaceSelected={(selected) => {
                                setUserLocation({
                                  lat: selected.geometry.location.lat(),
                                  lng: selected.geometry.location.lng(),
                                  name: selected.formatted_address
                                });
                              }}
                            />
                          )}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
                      <Field
                        component={MultiSelectField}
                        name='jobPosition'
                        label={gettext('APP.USERS.REGISTER_FORM.JOB_POSITION_LABEL')}
                        validate={required}
                        required
                        isMulti={false}
                        isSearchable={true}
                        options={jobPositions}
                      ></Field>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
                      <Field
                        component={TextField}
                        name='companyName'
                        id='companyName'
                        label={gettext('APP.USERS.REGISTER_FORM.COMPANY_NAME_LABEL')}
                        placeholder={gettext('APP.USERS.REGISTER_FORM.COMPANY_NAME_PLACEHOLDER')}
                        type='text'
                        validate={composeValidators(minLength(2, true))}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
                      <Field
                        component={TextField}
                        name='industry'
                        id='industry'
                        label={gettext('APP.USERS.REGISTER_FORM.INDUSTRY_LABEL')}
                        placeholder={gettext('APP.USERS.REGISTER_FORM.INDUSTRY_PLACEHOLDER')}
                        type='text'
                        validate={composeValidators(minLength(2, true))}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
                      <Field
                        component={MultiSelectField}
                        name='companySize'
                        label={gettext('APP.USERS.REGISTER_FORM.COMPANY_SIZE_LABEL')}
                        noasterisk='true'
                        isSearchable={false}
                        isMulti={false}
                        options={companySize.map((level, i) => {
                          return {
                            id: level,
                            name: level ? gettext('APP.USER.COMPANY_SIZE.' + level) : ''
                          };
                        })}
                      ></Field>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
                      <Field
                        component={MultiSelectField}
                        name='yearsOfExperience'
                        label={gettext('APP.USERS.REGISTER_FORM.YEARS_EXPERIENCE_LABEL')}
                        noasterisk='true'
                        isSearchable={false}
                        isMulti={false}
                        options={yearsOfExperience.map((level, i) => {
                          return {
                            id: level,
                            name: level ? gettext('APP.USER.YEARS_EXPERIENCE.' + level) : ''
                          };
                        })}
                      ></Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
                      <Field
                        component={TextField}
                        name='linkedInUrl'
                        id='linkedInUrl'
                        label={gettext('APP.USERS.REGISTER_FORM.LINKEDIN_LABEL')}
                        placeholder={gettext('APP.USERS.REGISTER_FORM.LINKEDIN_PLACEHOLDER')}
                        type='text'
                        validate={composeValidators(required, matchPattern('linkedIn', gettext('APP.USERS.REGISTER_FORM.LINKEDIN_RULES')))}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
                      <Field
                        component={MultiSelectField}
                        name='seniorityLevel'
                        label={gettext('APP.USERS.REGISTER_FORM.SENIORITY_LEVEL_LABEL')}
                        isSearchable={false}
                        isMulti={false}
                        options={seniorityLevels.map((level, i) => {
                          return {
                            id: level,
                            name: level ? gettext('APP.USER.SENIORITY_LEVEL.' + level) : ''
                          };
                        })}
                      ></Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
                      <Field
                        component={TextArea}
                        name='personalInformation'
                        id='personalInfo'
                        label={gettext('APP.USERS.REGISTER_FORM.PERSONAL_INFO_LABEL')}
                        placeholder={gettext('APP.USERS.REGISTER_FORM.PERSONAL_INFO_PLACEHOLDER')}
                        type='text'
                      />
                    </Grid>
                  </Grid>
                  <br />

                  <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                    <Grid className={'form-checkbox-container'} container>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <p>{gettext('APP.ADMIN.GOALS.LABEL')}</p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormGroup>
                          {goalsResponse.map((goal) => {
                            return (
                              <FormControlLabel
                                componentsProps={{ typography: { variant: 'formLabels' } }}
                                onChange={handleGoals}
                                disabled={goalsSelected.length === 3 && !goalsSelected.includes(goal.code) ? true : false}
                                key={goal.id}
                                control={<Checkbox value={goal.code} checked={goalsSelected.includes(goal.code) ? true : false} />}
                                label={gettext('APP.USERS.GOALS.' + goal.code)}
                              />
                            );
                          })}
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={10} md={10} lg={8} xl={7}>
                    {noGoalSelectedError && <Error text={gettext('APP.USER.REGISTER_FORM.NO_GOAL_SELECTED')} />}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {submitError && <Error text={gettext('APP.ADMIN.PROFILE_ERROR.' + submitError)} />}
                  </Grid>

                  <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                    <Grid className={'form-btns-container'}>
                      <button type='button' className={'cancel-btn profile-btns'} onClick={resetValues}>
                        Cancel
                      </button>
                      <button
                        type='button'
                        className={'save-btn profile-btns'}
                        type={'submit'}
                        onClick={() => {
                          setSubmitted(true);
                        }}
                      >
                        Save
                      </button>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default ProfileForm;
