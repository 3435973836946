// import axios from "axios";
// import {
//     CONVERSATION_MESSAGES,
//     CONVERSATION_PAGE_SIZE,
//     PARTICIPANT_MESSAGES,
//     UNEXPECTED_ERROR_MESSAGE,
// } from "./constants";
//
import { CONVERSATION_PAGE_SIZE, MessageStatus, UNEXPECTED_ERROR_MESSAGE } from '../../utils/consts';
// import {successNotification, unexpectedErrorNotification} from "./helpers";
import { getSdkMessageObject } from '../../utils/chat/conversationsObjects';

export async function addConversation(name, client) {
  if (name.length > 0 && client !== undefined) {
    try {
      const conversation = await client.createConversation({
        friendlyName: name
      });
      await conversation.join();
      return conversation;
    } catch (e) {
      return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
    }
  }
  return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
}

//
export async function addParticipant(name, proxyName, chatParticipant, convo) {
  if (convo === undefined) {
    return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
  }

  if (chatParticipant && name.length > 0) {
    try {
      const result = await convo.add(name);
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  if (!chatParticipant && name.length > 0 && proxyName.length > 0) {
    try {
      const result = await convo.addNonChatParticipant(proxyName, name, {
        friendlyName: name
      });

      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
}

//
// export async function getToken(
//     username,
//     password
// ) {
//     const requestAddress = process.env
//         .REACT_APP_ACCESS_TOKEN_SERVICE_URL;
//     if (!requestAddress) {
//         return Promise.reject(
//             "REACT_APP_ACCESS_TOKEN_SERVICE_URL is not configured, cannot login"
//         );
//     }
//
//     try {
//         const response = await axios.get(requestAddress, {
//             params: {identity: username, password: password},
//         });
//         return response.data;
//     } catch (error) {
//         if (axios.isAxiosError(error) && error.response?.status === 401) {
//             return Promise.reject(error.response.data ?? "Authentication error.");
//         }
//
//         process.stderr?.write(`ERROR received from ${requestAddress}: ${error}\n`);
//         return Promise.reject(`ERROR received from ${requestAddress}: ${error}\n`);
//     }
// }

export async function getMessageStatus(message, channelParticipants) {
  // FIXME should be: return statuses[message.sid];
  // after this modification:
  // message.on("updated", ({ message, updateReasons }) => {
  // if reason includes "deliveryReceipt" {
  //   // paginate detailed receipts
  //   const receipts = await message.getDetailedDeliveryReceipts(); // paginated backend query every time
  // }
  // });

  const statuses = {
    [MessageStatus.Delivered]: 0,
    [MessageStatus.Read]: 0,
    [MessageStatus.Failed]: 0,
    [MessageStatus.Sending]: 0
  };

  if (message.index === -1) {
    return Promise.resolve({
      ...statuses,
      [MessageStatus.Sending]: 1
    });
  }

  channelParticipants.forEach((participant) => {
    if (participant.identity == localStorage.getItem('username') || participant.type !== 'chat') {
      return;
    }

    if (participant.lastReadMessageIndex && participant.lastReadMessageIndex >= message.index) {
      statuses[MessageStatus.Read] += 1;
    } else if (participant.lastReadMessageIndex !== -1) {
      // statuses[MessageStatus.Delivered] += 1; FIXME don't need Delivered status for chat particpants?
    }
  });

  if (message.aggregatedDeliveryReceipt) {
    const sdkMessage = getSdkMessageObject(message);
    const receipts = await sdkMessage.getDetailedDeliveryReceipts(); // paginated backend query every time

    receipts.forEach((receipt) => {
      if (receipt.status === 'read') {
        statuses[MessageStatus.Read] += 1;
      }

      if (receipt.status === 'delivered') {
        statuses[MessageStatus.Delivered] += 1;
      }

      if (receipt.status === 'failed' || receipt.status === 'undelivered') {
        statuses[MessageStatus.Failed] += 1;
      }

      if (receipt.status === 'sent' || receipt.status === 'queued') {
        statuses[MessageStatus.Sending] += 1;
      }
    });
  }

  return statuses;
}

//
export const getConversationParticipants = async (conversation) => await conversation.getParticipants();
//
// export const removeParticipant = async (
//     conversation,
//     participant,
//     addNotifications
// ) => {
//     try {
//         await conversation.removeParticipant(participant);
//         successNotification({
//             message: PARTICIPANT_MESSAGES.REMOVED,
//             addNotifications,
//         });
//     } catch {
//         unexpectedErrorNotification(addNotifications);
//         return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
//     }
// };
//
// export const getBlobFile = async (
//     media,
//     addNotifications
// ) => {
//     try {
//         const url = await getFileUrl(media);
//         const response = await fetch(url);
//         return response.blob();
//     } catch (e) {
//         unexpectedErrorNotification(addNotifications);
//         return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
//     }
// };
//
// export const getFileUrl = async (media) => {
//     return await media.getContentTemporaryUrl().then();
// };

export const getMessages = async (conversation) => await conversation.getMessages(CONVERSATION_PAGE_SIZE);
