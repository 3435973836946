import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { debounce } from 'debounce';
import ReactTableFilters from '../ReactTable';

const ReactTablePagination = forwardRef(function ({ api, apiParam, dataProp = 'elements', ...props }, ref) {
  const [{ [dataProp]: elements, totalPages, totalElements, ...extraData }, setGridData] = useState({
    elements: [],
    totalElements: 0,
    totalPages: 0
  });
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [savedParams, setSavedParams] = useState();
  const tableRef = useRef();

  useImperativeHandle(ref, () => ({
    refresh() {
      tableRef.current.refresh();
    },
    selectRow(index) {
      tableRef.current.selectRow(index);
    },
    filterBy(index, filter) {
      tableRef.current.filterBy(index, filter);
    },
    filterByProp(prop, filter) {
      tableRef.current.filterByProp(prop, filter);
    },
    updateSingleRow(index, row) {
      if (totalElements > 0) {
        setGridData({
          elements: [...elements.slice(0, index), row, ...elements.slice(index + 1)],
          totalElements: totalElements,
          totalPages: totalPages
        });
        tableRef.current.refreshRow(index);
      }
    },
    updateSelectedRow(row) {
      const idx = tableRef.current.getSelectedIndex();
      idx &&
        setGridData({
          elements: [...elements.slice(0, idx), row, ...elements.slice(idx + 1)],
          totalElements: totalElements,
          totalPages: totalPages
        });
      tableRef.current.refreshSelectedRow();
    },
    getSelectedRow() {
      return elements && elements[tableRef.current.getSelectedIndex()];
    },
    getSearchParams() {
      return savedParams;
    }
  }));

  const onFetchData = useCallback((options) => {
    const sortColumn = props.columns.find((col) => col.accessor === options.sorted[0].id);

    const defaultParams = {
      page: options.page,
      size: options.pageSize,
      sorting_direction: options.sorted[0].desc ? 'DESC' : 'ASC',
      sorting_parameter: sortColumn && sortColumn.filterAs ? sortColumn.filterAs : options.sorted[0].id
    };

    const filters = options.filtered.reduce((acc, { id, value }) => {
      const col = props.columns.find((col) => col.accessor === id);
      const filterName = col && col.filterAs ? col.filterAs : id;
      return col
        ? typeof value === 'object' && value !== null && col.type === 'dateRange'
          ? { ...acc, [`f_from_${filterName}`]: value.dateFrom, [`f_to_${filterName}`]: value.dateTo }
          : { ...acc, [`${col.filterExact ? 'fe' : 'f'}_${filterName}`]: value }
        : acc;
    }, {});

    const params = { params: { ...defaultParams, ...filters } };
    setSavedParams(params);
    setLoading(true);
    api(params, apiParam)
      .then(({ data }) => {
        setLoading(false);
        if (data) {
          setGridData(data);
        }
      })
      .catch(() => setLoading(false));
  }, []);

  const defaultSorted = props.defaultSorted || [{ id: props.columns[0].accessor, desc: false }];

  return (
    <ReactTableFilters
      ref={tableRef}
      {...props}
      manual
      loading={loading}
      totalRows={totalElements}
      data={elements || []}
      pages={totalPages}
      onFetchData={debounce(onFetchData, 100)}
      defaultSorted={defaultSorted}
      selectRow={setSelectedRow}
      extraData={extraData}
    />
  );
});

export default ReactTablePagination;
