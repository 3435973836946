import React, { useEffect } from 'react';
import { useUserContext } from '../../contexts/userContext';
import { Navigate } from 'react-router-dom';
import { removeItem } from '../../utils/storage';

function Logoout() {
  const { user, setUser } = useUserContext();

  useEffect(() => {
    removeItem('RTNA');
    setUser({});
    // eslint-disable-next-line
  }, []);

  return <>{!user.username ? <Navigate to={'login'} /> : <></>}</>;
}

export default Logoout;
