import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { getToken } from '../services/userService';
import { getRememberedItem, removeItem, setItem } from '../utils/storage';

const refreshAuthLogic = (failedRequest) => {
  if (getRememberedItem('RTN')) {
    return getToken({
      refreshToken: getRememberedItem('RTN')
    })
      .then((tokenRefreshResponse) => {
        setItem('user', JSON.stringify({ token: tokenRefreshResponse.data.accessToken }));
        failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.accessToken;
        failedRequest.response.config.headers['Content-Type'] = 'application/json';
        return Promise.resolve();
      })
      .catch((error) => {
        removeItem('RTN');
        window.location.href = '/logout';
      });
  } else {
    if (failedRequest.response.status == 401) {
      window.location.href = '/logout';
    }
    return Promise.reject(failedRequest.response);
  }
};

createAuthRefreshInterceptor(axios, refreshAuthLogic);

let cancelTokens = [];

export function downloadGet(url, apiParams, filename) {
  return axios({
    url: url,
    method: 'GET',
    responseType: 'blob',
    params: {
      ...apiParams.params
    }
  }).then(downloader(filename));
}

export function get(url, apiParams) {
  if (cancelTokens['get_' + url]) {
    cancelTokens['get_' + url].cancel('Operation canceled due to new request.');
  }
  cancelTokens['get_' + url] = axios.CancelToken.source();
  let params = { ...apiParams, cancelToken: cancelTokens['get_' + url].token };
  return axios.get(url, params);
}

export function post(url, apiParams, options) {
  if (cancelTokens['post_' + url]) {
    cancelTokens['post_' + url].cancel('Operation canceled due to new request.');
  }
  cancelTokens['post_' + url] = axios.CancelToken.source();
  let params = { ...apiParams, cancelToken: cancelTokens['post_' + url].token };
  return axios.post(url, params, options);
}

export function postFile(url, apiParams, options) {
  return axios.post(url, apiParams, options);
}

export function remove(url, apiParams) {
  if (cancelTokens['remove_' + url]) {
    cancelTokens['remove_' + url].cancel('Operation canceled due to new request.');
  }
  cancelTokens['remove_' + url] = axios.CancelToken.source();
  let params = { ...apiParams, cancelToken: cancelTokens['remove_' + url].token };
  return axios.delete(url, params);
}

export function put(url, apiParams) {
  if (cancelTokens['put_' + url]) {
    cancelTokens['put_' + url].cancel('Operation canceled due to new request.');
  }
  cancelTokens['put_' + url] = axios.CancelToken.source();
  let params = { ...apiParams, cancelToken: cancelTokens['put_' + url].token };
  return axios.put(url, params, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

export function downloader(outfile) {
  return (response) => {
    const header = response.headers['content-disposition'];
    const filename = header ? /filename="(.+)?"/g.exec(header)[1] : outfile;
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      var csvData = new Blob([response.data], { type: 'text/csv' });
      window.navigator.msSaveOrOpenBlob(csvData, filename);
    } else {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    }
  };
}
