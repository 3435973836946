import React, { useEffect, useRef, useState } from 'react';
import { useLanguageContext } from '../../contexts/languageContext';
import loader from '../../images/loader.gif';

export default function FileUpload({ onFileAdded, loading, accept }) {
  const [hightlight, setHightlight] = useState(false);
  const [choosenFile, setChoosenFile] = useState(false);
  const { gettext } = useLanguageContext();
  const ref = useRef();

  const onDragOver = (e) => {
    e.preventDefault();
    setHightlight(true);
  };
  const onDragLeave = () => setHightlight(false);
  const onDrop = (e) => {
    const file = e.dataTransfer.files[0];
    e.preventDefault();
    setChoosenFile(file);
    onFileAdded(file);
    setHightlight(false);
  };
  const onOpenDialog = () => ref.current.click();
  const onAddFile = ({ target }) => {
    const file = target.files[0];
    setChoosenFile(file);
    onFileAdded(file);
    ref.current.value = '';
  };

  const [preview, setPreview] = useState();
  useEffect(() => {
    // create the preview
    if (choosenFile) {
      const objectUrl = URL.createObjectURL(choosenFile);
      setPreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }

    // free memory when ever this component is unmounted
  }, [choosenFile]);

  return (
    <div
      className={`dropzone ${hightlight ? 'dropzone-hightlight' : ''}`}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      onClick={onOpenDialog}
    >
      {!preview && <span>{gettext('APP.USERS.FILE.CHOOSE_FILE')}</span>}
      {preview && <img src={preview} />}
      {loading ? (
        <div width='100%' style={{ textAlign: 'center' }}>
          <img src={loader} alt='' style={{ width: 'auto' }} />
        </div>
      ) : (
        <></>
      )}
      <input ref={ref} type='file' multiple={false} className='file-input' onChange={onAddFile} accept={accept} />
    </div>
  );
}
