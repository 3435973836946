import React, { useContext, useState } from 'react';

const AttachmentsContext = React.createContext();

export const useAttachmentsContext = () => useContext(AttachmentsContext);

export function AttachmentsProvider({ children }) {
  const [attachments, setAttachmentsToState] = useState([]);
  const [askToRegister, setAskToRegister] = useState(true);

  return (
    <AttachmentsContext.Provider
      value={{
        attachments,
        setAttachments: (attachments) => {
          setAttachmentsToState(attachments);
        }
      }}
    >
      {children}
    </AttachmentsContext.Provider>
  );
}
