import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { getUser } from '../../utils/user';

class ErrorBoundary extends Component {
  state = {
    hasError: false
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    axios.interceptors.request.use(
      (config) => {
        const userData = getUser();
        if (!config.params) {
          config.params = {};
        }
        config.params['browser-language'] = 'en';
        config.headers['Accept-Language'] = 'en';
        if (userData.token) {
          config.headers['Authorization'] = `Bearer ${userData.token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  componentDidCatch(error, info) {
    console.log(error);
    this.setState();
  }

  render() {
    return <Fragment>{this.props.children}</Fragment>;
  }
}

export default ErrorBoundary;
