import React, { useEffect, useState } from 'react';
import { useParticipantsContext } from '../../../../contexts/ParticipantsContext';
import { useUserContext } from '../../../../contexts/userContext';
import { Avatar, ListItem } from '@mui/material';
import accountImage from '../../../../images/default_account.png';
import moment from 'moment';

const MessageView = (props) => {
  const { text, getStatus, onDeleteMessage, author, message, conversation } = props;

  const [status, setStatus] = useState({});
  const { participants } = useParticipantsContext();
  const { user } = useUserContext();
  const [messageAuthor, setMessageAuthor] = useState({});

  useEffect(() => {
    setMessageAuthor(conversation[message.author]);
    getStatus.then((value) => setStatus(value));
  }, [getStatus]);

  return (
    <ListItem className={'message-box'}>
      {messageAuthor && (
        <>
          <div className={'chat-avatar'}>
            {messageAuthor.imageUrl ? (
              <Avatar alt={messageAuthor.fullName} src={messageAuthor.imageUrl} />
            ) : (
              <Avatar alt={messageAuthor.fullName} src={accountImage} />
            )}
          </div>
          <div className={'chat-user'}>
            <div className={'chat-user-line-one'}>
              <div className={'chat-user-name'}>{messageAuthor.fullName}</div>
              <div className={'chat-message-date'}>{moment.utc(props.messageTime).local().format('HH:mm').toString()}</div>
            </div>
            <div className={'chat-user-line-two'}>
              <div className={'chat-message'}>
                {props.media}
                {props.text}
              </div>
            </div>
            {/*<Reactions*/}
            {/*    reactions={props.reactions}*/}
            {/*    onReactionsChanged={(reactions) =>*/}
            {/*        props.updateAttributes({reactions})*/}
            {/*    }*/}
            {/*/>*/}
          </div>
        </>
      )}
    </ListItem>
  );
};

export default MessageView;
