import React, { useEffect, useRef, useState } from 'react';
import './styles.css';
import { Chip, Grid, IconButton, Tooltip } from '@mui/material';
import { useLanguageContext } from '../../contexts/languageContext';
import ReactTablePagination from '../ReactTablePagination';
import { exportUsers, getUsers } from '../../services/userService';
import accountImage from '../../images/default_account.png';
import { MultiSelectFilter, TextDropdownFilter } from '../ReactTable';
import { getJobPositions } from '../../services/masterDataService';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Link } from 'react-router-dom';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import { yesNoOptionsEN } from '../../utils/consts';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';

function Users() {
  const { gettext } = useLanguageContext();
  const [jobPositions, setJobPositions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const tableRef = useRef();

  useEffect(() => {
    getJobPositions()
      .then((result) => {
        let jobs = result.data.map((job) => {
          return {
            id: job,
            name: gettext('APP.USERS.JOBS.' + job)
          };
        });
        jobs.unshift({
          id: '',
          name: ''
        });
        setJobPositions(jobs);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      header: gettext('APP.ADMINS.USERS.FIRST_NAME'),
      accessor: 'firstName',
      Cell: (data) => {
        const name = data.row.original.firstName;
        const image = data.row.original.imageUrl;
        return (
          <div className={'name'}>
            <div className={'profile-picture'}>
              <Link to={'/profile/' + data.row.original.id}>{image ? <img src={image} alt='Profile' /> : <img src={accountImage} alt='Profile' />}</Link>
            </div>
            <div>
              <Link to={'/profile/' + data.row.original.id}>
                <div>{name}</div>
              </Link>
            </div>
          </div>
        );
      },
      width: '12%'
    },
    {
      header: gettext('APP.ADMINS.USERS.SURNAME'),
      accessor: 'lastName',
      Cell: (data) => {
        const name = data.row.original.lastName;
        return (
          <div className={'name'}>
            <div>
              <Link to={'/profile/' + data.row.original.id}>{name}</Link>
            </div>
          </div>
        );
      },
      width: '13%'
    },
    {
      header: gettext('APP.ADMINS.USERS.EMAIL'),
      accessor: 'username',
      width: '20%'
    },
    {
      header: gettext('APP.ADMINS.USERS.CITY'),
      accessor: 'location.name',
      width: '10%'
    },
    {
      header: gettext('APP.ADMINS.USERS.COMPANY'),
      accessor: 'companyName',
      width: '15%'
    },
    {
      header: gettext('APP.ADMINS.USERS.JOB_POSITION'),
      accessor: 'jobPosition',
      Cell: (data) => {
        const value = data.row.original.jobPosition;
        return <>{value ? gettext('APP.USERS.JOBS.' + value) : ''}</>;
      },
      Filter: MultiSelectFilter(jobPositions, false, gettext('APP.ADMINS.USERS.JOB_POSITION')),
      width: '30%'
    },

    {
      header: gettext('APP.ADMINS.USERS.SUBSCRIBED'),
      accessor: 'subscribe',
      filterAs: 'isSubscribe',
      Cell: ({ value }) => {
        return gettext(`${value === true ? 'yes' : value === false ? 'no' : ''}`);
      },
      Filter: TextDropdownFilter(yesNoOptionsEN, true),
      width: '5%'
    },

    {
      header: '',
      accessor: 'roles',
      Cell: (data) => {
        const roles = data.row.original.roles;

        return (
          <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
            {roles.includes('ROLE_ADMIN') && <Chip className={'chip'} label='Admin' />}
          </Grid>
        );
      },
      Filter: () => {}
    }
  ];
  const actions = [
    {
      iconcls: <ModeEditOutlineOutlinedIcon />,
      title: gettext('APP.COMMON.COMMON.EDIT'),
      onClick: (dummyParam, { row }) => {
        window.location.href = '/users/' + row.original.id + '/edit';
      }
    },
    {
      iconcls: <ForumOutlinedIcon />,
      title: gettext('APP.COMMON.COMMON.CHAT'),
      onClick: (dummyParam, { row }) => {
        window.location.href = '/chat?chatId=' + row.original.id;
      }
    }
  ];
  const renderTable = (
    <ReactTablePagination
      defaultPageSize={10}
      ref={tableRef}
      columns={columns}
      api={getUsers}
      actions={actions}
      dependencies={[jobPositions]}
      customButtons={
        <div style={{ display: 'flex' }}>
          <div style={{ height: '26px' }}>
            <Tooltip title={gettext('APP.COMMON.COMMON.EXPORT_CSV')}>
              <IconButton
                disabled={downloading}
                onClick={() => {
                  setDownloading(true);
                  return exportUsers(tableRef.current.getSearchParams()).then(() => setDownloading(false));
                }}
              >
                {!downloading && <FileDownloadOutlinedIcon />}
                {downloading && <HourglassEmptyOutlinedIcon />}
              </IconButton>
            </Tooltip>
          </div>
        </div>
      }
    />
  );

  return (
    <>
      <Grid container className='user-page-container'>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <h1 className={'profile-title'}>{gettext('APP.USERS.USERS.TITLE')}</h1>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'users-create-new'}>
          <button
            type='button'
            className={'upload-btn profile-btns'}
            onClick={() => {
              window.location.href = '/users/create';
            }}
          >
            {gettext('APP.ADMINS.USERS.CREATE')}
          </button>
        </Grid>
        <Grid container className={'users-table-wrapper'} justifyContent={'center'}>
          <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
            {!loading && renderTable}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Users;
