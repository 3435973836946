import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import MessageList from '../MessageList';
// import styles from "./styles";
import './styles.css';
import { getMessages } from '../../api';
import { CONVERSATION_PAGE_SIZE } from '../../../../utils/consts';
import { getSdkConversationObject } from '../../../../utils/chat/conversationsObjects';

export async function loadMessages(conversation, upsertMessage, currentMessages) {
  const convoSid = conversation.sid;
  if (typeof currentMessages === 'undefined') currentMessages = [];
  const sidExists = !!currentMessages.filter(({ sid }) => sid === convoSid).length;
  if (!sidExists && getSdkConversationObject(conversation)) {
    const paginator = await getMessages(getSdkConversationObject(conversation));
    const messages = paginator.items;

    upsertMessage(convoSid, messages);
  }
}

const MessagesBox = (props) => {
  const { messages, convo, loadingState, lastReadIndex, upsertMessage, convoSid } = props;
  const [hasMore, setHasMore] = useState(messages?.length === CONVERSATION_PAGE_SIZE);
  const [loading, setLoading] = useState(false);
  const [height, setHeight] = useState(0);
  const [paginator, setPaginator] = useState(null);
  const listRef = useRef(null);

  const sdkConvo = useMemo(() => getSdkConversationObject(convo), [convo.sid]);

  useEffect(() => {
    if (!messages && convo) {
      loadMessages(convo, upsertMessage);
    }
  }, []);

  useLayoutEffect(() => {
    const currentHeight = listRef.current?.clientHeight;
    if (currentHeight && currentHeight > height && loading) {
      // for preventing immediate downloading of the next messages page
      setTimeout(() => {
        setHeight(currentHeight ?? 0);
        setLoading(false);
      }, 2000);
    }
  }, [listRef.current?.clientHeight]);

  useEffect(() => {
    if (sdkConvo) {
      getMessages(sdkConvo).then((paginator) => {
        setHasMore(paginator.hasPrevPage);
        setPaginator(paginator);
      });
    }
  }, [sdkConvo, convo]);

  useEffect(() => {
    if (messages?.length && messages[messages.length - 1].index !== -1 && sdkConvo) {
      sdkConvo.advanceLastReadMessageIndex(messages[messages.length - 1].index);
    }
  }, [messages, convo]);

  const lastConversationReadIndex = useMemo(
    () => (messages?.length && messages[messages.length - 1].author !== localStorage.getItem('username') ? lastReadIndex : -1),
    [lastReadIndex, messages]
  );

  const fetchMore = async () => {
    if (!paginator) {
      return;
    }

    const result = await paginator?.prevPage();
    if (!result) {
      return;
    }
    const moreMessages = result.items;

    setLoading(true);
    setPaginator(result);
    setHasMore(result.hasPrevPage);
    upsertMessage(convo.sid, moreMessages);
  };

  return (
    <>
      <InfiniteScroll
        dataLength={messages?.length ?? 0}
        next={fetchMore}
        hasMore={!loading && hasMore}
        loader={<div>{/*<Loadin decorative={false} size="sizeIcon50" title="Loading" />*/}</div>}
        scrollableTarget='scrollable'
        scrollThreshold='20px'
        inverse={true}
      >
        <MessageList messages={messages ?? []} conversation={convo} participants={props.participants} lastReadIndex={lastConversationReadIndex} />
      </InfiniteScroll>
    </>
  );
};

export default MessagesBox;
