import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './sass/master.scss';
import App from './components/App/App';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import PageWrapper from './pages/PageWrapper';
import GuestPageWrapper from './pages/GuestPageWrapper';
import Login from './components/Login';
import Logout from './components/Logout';
import ProfileForm from './components/ProfileForm';
import ProtectedComponent from './components/ProtectedComponent';
import Users from './components/Users';
import Profile from './components/Profile';
import Hookups from './components/Hookups';
import Timecycles from './components/Timecycles';
import HookupForm from './components/HookupForm';
import Chat from './components/Chat';
import Reviews from './components/Reviews';

const router = createBrowserRouter([
  {
    path: '',
    element: <App />,
    errorElement: <div>Not Found</div>,
    children: [
      {
        path: '',
        element: (
          <ProtectedComponent>
            <PageWrapper />
          </ProtectedComponent>
        ),
        children: [
          {
            path: '',
            element: <div>Home Page</div>
          },
          {
            path: 'users/create',
            element: <ProfileForm />
          },
          {
            path: 'profile/:userId',
            element: <Profile />
          },
          {
            path: 'users/:userId/edit',
            element: <ProfileForm />
          },
          {
            path: 'profile',
            element: <ProfileForm />
          },
          {
            path: 'logout',
            element: <Logout />
          },
          {
            path: 'users',
            element: <Users />
          },
          {
            path: 'hookups',
            element: <Hookups />
          },
          {
            path: 'reviews',
            element: <Reviews />
          },
          {
            path: 'hookups/:hookuupId/edit',
            element: <HookupForm />
          },
          {
            path: 'timecycles',
            element: <Timecycles />
          },
          {
            path: 'chat',
            element: <Chat />
          }
        ]
      },
      {
        path: '',
        element: <GuestPageWrapper />,
        children: [
          {
            path: 'login',
            element: <Login />
          }
        ]
      }
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
