import moment from 'moment';

const pattern = {
  password: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[^\w\s])(?=\S+$).{8,}$/, //Minimum eight characters, at least one letter and one number:
  linkedIn: /^(http(s)?:\/\/)?(w{3}\.)?(((?!ww)..\.)?linkedin\.com\/(in|profile|pub)\/(.)+)$/,
  email: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  phone: /^\+(?:[0-9] ?){6,14}[0-9]$/
};

export function required(value) {
  return value != null && value !== '' ? undefined : 'APP.COMMON.FORM.REQUIRED';
}

export function requiredTrue(value) {
  return value === true ? undefined : 'APP.COMMON.FORM.REQUIRED';
}

export function requiredMulti(value) {
  return value && value.length ? undefined : 'APP.COMMON.FORM.ATLEAST_ONE_VALUE';
}

export function requiredIfOther(field) {
  return (value, allValues) => (allValues[field] ? required(value) : undefined);
}

export function minLength(min, canBeEmpty) {
  return (value) => {
    if (canBeEmpty && !value) return undefined;
    return value && value.length >= min ? undefined : { text: 'APP.COMMON.FORM.LEAST_SYMBOLS', values: [min] };
  };
}

export function maxLength(max, canBeEmpty) {
  return (value) => {
    if (canBeEmpty && !value) return undefined;
    return value && value.length <= max ? undefined : { text: 'APP.COMMON.FORM.MOST_SYMBOLS', values: [max] };
  };
}

export function matchPattern(type, message, canBeEmpty) {
  return (value) => {
    if (canBeEmpty && !value) return undefined;
    return value && value.match(pattern[type]) ? undefined : message;
  };
}

export function floatDigitsNullAllowed(value) {
  return /^[0-9.]+$/.test(value) ? undefined : 'APP.COMMON.FORM.WRONG_FORMAT';
}

export function floatDigits(value) {
  return value && /^[0-9.]+$/.test(value) ? undefined : 'APP.COMMON.FORM.WRONG_FORMAT';
}

export function digitsOnly(value) {
  return value && /^[0-9]+$/.test(value) ? undefined : 'APP.COMMON.FORM.WRONG_FORMAT';
}

export function composeValidators(...validators) {
  return (value) => validators.reduce((error, validator) => error || validator(value), undefined);
}

export const composeValidatorsAllValues =
  (...validators) =>
  (value, allValues, fieldState) =>
    validators.reduce((error, validator) => error || validator(value, allValues, fieldState), undefined);

export function dateNotPast(value) {
  return moment(value).isAfter(moment(), 'minutes') ? undefined : 'APP.COMMON.FORM.DATE_IN_PAST';
}

export function validatePhone(value) {
  return value && regexPhone.test(value) ? undefined : 'APP.COMMON.FORM.WRONG_FORMAT';
}

export function validateEmail(value) {
  return value && regexEmail.test(value) ? undefined : 'APP.COMMON.FORM.WRONG_FORMAT';
}

export function matchPasswords(password, message) {
  return (value) => (value === password ? undefined : message);
}

export const regexEmail =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
export const regexPhone = /^[0-9+]+$/;
