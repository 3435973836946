import React, { useEffect, useState } from 'react';
import './styles.css';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useLanguageContext } from '../../contexts/languageContext';
import accountImage from '../../images/default_account.png';
import { deleteUser, getUserDetails } from '../../services/userService';
import { useParams } from 'react-router-dom';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';

function Profile() {
  const { gettext } = useLanguageContext();
  let { userId } = useParams();
  const [currentUser, setCurrentUser] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (userId) {
      getUserDetails(userId).then((result) => {
        setCurrentUser(result.data);
      });
    }
  }, [userId]);

  const onSubmitDelete = () => {
    deleteUser(userId).then((result) => {
      window.location.href = '/users';
    });
  };

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = (submitForm) => {
    setOpen(false);
    if (submitForm) onSubmitDelete();
  };

  return (
    <>
      <Dialog open={open} onClose={() => closeDialog(false)} aria-labelledby='responsive-dialog-title'>
        <DialogTitle id='responsive-dialog-title' className={'mangueira'}>
          {gettext('APP.USER.SETTINGS_WARNINGS.DELETE_USER_TITLE')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{gettext('APP.USER.SETTINGS_WARNINGS.DELETE_USER_TEXT')}</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', margin: '10px 0px' }}>
          <Grid className={'form-btns-container'}>
            <button type='button' className={'reset-btn profile-btns'} onClick={() => closeDialog(false)}>
              {gettext('APP.USERS.COMMON.BUTTON_CANCEL')}
            </button>
            <button type='button' className={'upload-btn profile-btns'} onClick={() => closeDialog(true)}>
              {gettext('APP.USERS.COMMON.BUTTON_DELETE')}
            </button>
          </Grid>
        </DialogActions>
      </Dialog>
      <Grid container className='profile-page-container'>
        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
          <Grid container>
            <Grid item className={'profile-image-wrapper'}>
              <Typography component='div' className={'profile-image'}>
                {currentUser.imageUrl ? <img src={currentUser.imageUrl} alt='Profile' /> : <img src={accountImage} alt='Profile' />}
              </Typography>
            </Grid>
            <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
              <div className={'header'}>
                {currentUser.firstName} {currentUser.lastName}
              </div>
              <div className={'label'}>{currentUser.username}</div>
              <div className={'label'}>{currentUser.linkedInUrl}</div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className={'text-right'}>
          <Grid>
            <button type='button' className={'upload-btn profile-btns'} onClick={openDialog}>
              {gettext('APP.USERS.COMMON.BUTTON_DELETE_USER')}
            </button>

            {userId && (
              <button
                type='button'
                className={'cancel-btn profile-btns'}
                style={{ width: '140px' }}
                onClick={() => {
                  window.location.href = '/users/' + userId + '/edit';
                }}
              >
                {gettext('APP.USERS.COMMON.BUTTON_EDIT_USER')}
              </button>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid container className='profile-page-container'>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className={'profile-data'}>
          <div>
            <div className={'label'}>{gettext('APP.USERS.REGISTER_FORM.CITY_LABEL')}</div>
            <div>{currentUser.city ? currentUser.city : '-'}</div>
          </div>
          <div>
            <div className={'label'}>{gettext('APP.USERS.REGISTER_FORM.LOCATION_LABEL')}</div>
            <div>{currentUser.location && currentUser.location.name ? currentUser.location.name : '-'}</div>
          </div>
          <div>
            <div className={'label'}>{gettext('APP.USERS.REGISTER_FORM.JOB_POSITION_LABEL')}</div>
            <div>{currentUser.jobPosition ? gettext('APP.USERS.JOBS.' + currentUser.jobPosition) : '-'}</div>
          </div>
          <div>
            <div className={'label'}>{gettext('APP.USERS.REGISTER_FORM.COMPANY_NAME_LABEL')}</div>
            <div>{currentUser.companyName ? currentUser.companyName : '-'}</div>
          </div>
          <div>
            <div className={'label'}>{gettext('APP.USERS.REGISTER_FORM.COMPANY_SIZE_LABEL')}</div>
            <div>{currentUser.companySize ? gettext('APP.USER.COMPANY_SIZE.' + currentUser.companySize) : '-'}</div>
          </div>
          <div>
            <div className={'label'}>{gettext('APP.USERS.REGISTER_FORM.SENIORITY_LEVEL_LABEL')}</div>
            <div>{currentUser.seniorityLevel ? gettext('APP.USER.SENIORITY_LEVEL.' + currentUser.seniorityLevel) : '-'}</div>
          </div>
          <div>
            <div className={'label'}>{gettext('APP.USERS.REGISTER_FORM.YEARS_EXPERIENCE_LABEL')}</div>
            <div>{currentUser.yearsOfExperience ? gettext('APP.USER.YEARS_EXPERIENCE.' + currentUser.yearsOfExperience) : '-'}</div>
          </div>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className={'profile-data'}>
          <div>
            <div className={'label'}>{gettext('APP.USERS.REGISTER_FORM.GOALS_LABEL')}</div>
            <div>
              <ul>
                {currentUser.goals &&
                  currentUser.goals.map((goal) => {
                    return <li key={goal}>{gettext('APP.USERS.GOALS.' + goal)}</li>;
                  })}
              </ul>
            </div>
          </div>

          <div>
            <div className={'label'}>{gettext('APP.USERS.REGISTER_FORM.PERSONAL_INFO_LABEL')}</div>
            <div>{currentUser.personalInformation ? currentUser.personalInformation : '-'}</div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default Profile;
