import React, { useEffect, useState } from 'react';
import { MessageStatus, NOTIFICATION_LEVEL } from '../../../../utils/consts';
import { getMessageStatus } from '../../api';

import * as _ from 'lodash';
import { Avatar, ListItem } from '@mui/material';
import { useLocation } from 'react-router-dom';

import accountImage from '../../../../images/default_account.png';
import { useUserContext } from '../../../../contexts/userContext';
import moment from 'moment';

const measureWidth = (text) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) {
    return 0;
  }
  context.font = 'bold 14px Inter';
  return context.measureText(text).width;
};

function calculateUnreadMessagesWidth(count) {
  if (count === 0 || !count) {
    return 0;
  }
  return measureWidth(count.toString()) + 32;
}

function truncateMiddle(text, countWidth) {
  const width = measureWidth(text);
  if (width > 288 - countWidth) {
    const textLength = text.length;
    const avgLetterSize = width / textLength;
    const nrOfLetters = (288 - countWidth) / avgLetterSize;
    const delEachSide = (textLength - nrOfLetters + 1) / 2;
    const endLeft = Math.floor(textLength / 2 - delEachSide);
    const startRight = Math.ceil(textLength / 2 + delEachSide);
    return text.substr(0, endLeft) + '...' + text.substr(startRight);
  }
  return text;
}

function getLastMessageDate(messages) {
  const lastMessageDate = _.last(messages)?.dateCreated;
  if (!lastMessageDate) {
    return '';
  }

  return moment.utc(lastMessageDate).local().format('DD.MM.YYYY').toString();
}

function ConversationView(props) {
  const { convo, convoId, myMessage, lastMessage, unreadMessagesCount } = props;
  const [backgroundColor, setBackgroundColor] = useState();
  const { user, setUser } = useUserContext();
  const title = truncateMiddle(convo.friendlyName ?? convo.sid, calculateUnreadMessagesWidth(unreadMessagesCount));
  const [secondParticipant, setSecondParticipant] = useState({});
  const muted = convo.notificationLevel === NOTIFICATION_LEVEL.MUTED;

  const [lastMsgStatus, setLastMsgStatus] = useState('');
  const messageDate = getLastMessageDate(props.messages);
  const params = new URLSearchParams(useLocation().search);

  useEffect(() => {
    let secondParticipantId = convo.friendlyName.replace('_', '').replace(user.id, '');
    setSecondParticipant(convo[secondParticipantId]);
  }, [props.currentConvoSid, convo.sid]);

  useEffect(() => {
    if (myMessage && !props.typingInfo.length) {
      getMessageStatus(myMessage, props.participants).then((statuses) => {
        if (statuses[MessageStatus.Read]) {
          setLastMsgStatus(MessageStatus.Read);
          return;
        }
        if (statuses[MessageStatus.Delivered]) {
          setLastMsgStatus(MessageStatus.Delivered);
          return;
        }
        if (statuses[MessageStatus.Failed]) {
          setLastMsgStatus(MessageStatus.Failed);
          return;
        }
        if (statuses[MessageStatus.Sending]) {
          setLastMsgStatus(MessageStatus.Sending);
          return;
        }
      });
    }
  }, [convo, myMessage, lastMessage, props.participants, props.typingInfo]);

  return (
    <div>
      <ListItem className={'chat-box'} button id={convoId} onClick={props.onClick}>
        <div className={'chat-avatar'}>
          {secondParticipant && secondParticipant.imageUrl ? (
            <Avatar alt='Remy Sharp' src={secondParticipant.imageUrl} />
          ) : (
            <Avatar alt='Remy Sharp' src={accountImage} />
          )}
        </div>
        <div className={'chat-user'}>
          <div className={'chat-user-line-one'}>
            <div className={'chat-user-name'}>{secondParticipant && secondParticipant.fullName}</div>
            <div className={'chat-message-date'}>{messageDate}</div>
          </div>
          <div className={'chat-user-line-two'}>
            <div className={'chat-last-message'}> {lastMessage}</div>
            <div className={'chat-unread-messages'}>{unreadMessagesCount > 0 && <div className={'number-circle'}>{unreadMessagesCount}</div>}</div>
          </div>
        </div>
      </ListItem>
    </div>
  );
}

export default ConversationView;
