import React, { useRef, useState } from 'react';
import './styles.css';
import { Chip, Grid, IconButton, Tooltip } from '@mui/material';
import { useLanguageContext } from '../../contexts/languageContext';
import ReactTablePagination from '../ReactTablePagination';
import accountImage from '../../images/default_account.png';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Link } from 'react-router-dom';
import { getHookups, exportHookups } from '../../services/hookupsService';
import moment from 'moment';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';

/**
 * Renders a table of hookups with pagination, filtering, and sorting functionality.
 * Allows the user to export the table data as a CSV file.
 *
 * @returns {JSX.Element} The rendered table of hookups.
 */
function Hookups() {
  const { gettext } = useLanguageContext();
  const tableRef = useRef();
  const [downloading, setDownloading] = useState(false);

  const renderParticipantNameCell = (data) => {
    const { imageUrl, fullName, id } = data.row.original.firstParticipant;
    const image = imageUrl || accountImage;
    const name = fullName;

    return (
      <div className="name">
        <div className="profile-picture">
          <img src={image} alt="Profile" />
        </div>
        <div>
          <Link to={`/profile/${id}`}>{name}</Link>
        </div>
      </div>
    );
  };

  const renderSecondParticipantNameCell = (data) => {
    
    if (!data?.row?.original?.secondParticipant) return <div></div>;

    const { imageUrl, fullName, id } = data?.row?.original?.secondParticipant ?? {};
    const image = imageUrl || accountImage;
    const name = fullName;

    return (
      <div className="name">
        <div className="profile-picture">
          <img src={image} alt="Profile" />
        </div>
        <div>
          <Link to={`/profile/${id}`}>{name}</Link>
        </div>
      </div>
    );
  };

  const renderDateCell = (data) => {
    const formattedDate = moment.utc(data.row.original.startDateTime).local().format('DD.MM.YYYY').toString();

    return <div>{formattedDate}</div>;
  };

  const renderTimeCell = (data) => {
    const formattedTime = moment.utc(data.row.original.startDateTime).local().format('HH:mm').toString();

    return <div>{formattedTime}</div>;
  };

  const renderTimeCycleNameCell = (data) => {
    const timeCycleName = data.row.original.timeCycleName;

    return <div>{timeCycleName}</div>;
  };

  const renderDefaultChipCell = (data) => {
    const isDefaultTimeCycle = data.row.original.isDefaultTimeCycle;

    return (
      <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
        {isDefaultTimeCycle && <Chip className="chip" label="Default" />}
      </Grid>
    );
  };

  const columns = [
    {
      header: gettext('APP.ADMINS.HOOKUPS.FIRST_PARTICIPANT_NAME'),
      accessor: 'firstParticipant.fullName',
      Cell: renderParticipantNameCell,
      width: '25%'
    },
    {
      header: gettext('APP.ADMINS.HOOKUPS.SECOND_PARTICIPANT_NAME'),
      accessor: 'secondParticipant.fullName',
      Cell: renderSecondParticipantNameCell,
      width: '25%'
    },
    {
      header: gettext('APP.ADMINS.HOOKUPS.DATE'),
      accessor: 'startDateTime',
      Filter: () => {},
      Cell: renderDateCell,
      width: '20%'
    },
    {
      header: gettext('APP.ADMINS.HOOKUPS.TIME'),
      accessor: 'startDateTime2',
      Cell: renderTimeCell,
      Filter: () => null,
      width: '20%'
    },
    {
      header: gettext('APP.ADMINS.TIMESLOT.NAME_LABEL'),
      accessor: 'timeCycleName',
      Cell: renderTimeCycleNameCell,
      width: '30%'
    },
    {
      header: '',
      accessor: 'isDefaultTimeCycle',
      Cell: renderDefaultChipCell
    }
  ];

  const actions = [
    {
      iconcls: <ModeEditOutlineOutlinedIcon />,
      title: gettext('APP.COMMON.COMMON.EDIT'),
      condition: ({ original }) => {
        if (original.createdOn) {
          return moment.utc(original.createdOn).local().add(1, 'hours') > moment();
        }
      },
      onClick: (dummyParam, { row }) => {
        window.location.href = `/hookups/${row.original.id}/edit`;
      }
    }
  ];

  const renderTable = (
    <ReactTablePagination
      defaultPageSize={10}
      ref={tableRef}
      columns={columns}
      api={getHookups}
      actions={actions}
      dependencies={[]}
      defaultSorted={[
        {
          id: 'createdOn',
          desc: true
        }
      ]}
      customButtons={
        <div style={{ display: 'flex' }}>
          <div style={{ height: '26px' }}>
            <Tooltip title={gettext('APP.COMMON.COMMON.EXPORT_CSV')}>
              <IconButton
                disabled={downloading}
                onClick={() => {
                  setDownloading(true);
                  return exportHookups(tableRef.current.getSearchParams()).then(() => setDownloading(false));
                }}
              >
                {!downloading && <FileDownloadOutlinedIcon />}
                {downloading && <HourglassEmptyOutlinedIcon />}
              </IconButton>
            </Tooltip>
          </div>
        </div>
      }
    />
  );

  return (
    <>
      <Grid container className="user-page-container">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <h1 className="profile-title">{gettext('APP.HOOKUPS.HOOKUPS.TITLE')}</h1>
        </Grid>
        <Grid container className="users-table-wrapper" justifyContent="center">
          <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
            {renderTable}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Hookups;
