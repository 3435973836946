import { get, post, put, remove } from '../utils/api';
import { BASE_URL } from '../utils/consts';

export function getTimecycles() {
  return get(`${BASE_URL}/hookup-admin/time-cycle`);
}

export function deleteTimecycle(timecycleId) {
  return remove(`${BASE_URL}/hookup-admin/time-cycle?id=${timecycleId}`);
}

export function createTimecycle(timecycle) {
  return post(`${BASE_URL}/hookup-admin/time-cycle`, timecycle);
}

export function updateTimecycle(timecycle) {
  return put(`${BASE_URL}/hookup-admin/time-cycle`, timecycle);
}
