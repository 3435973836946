export const getTypingMessage = (typingData) =>
  typingData.length > 1 ? `${typingData.length + ' participants are typing...'}` : `${typingData[0] + ' is typing...'}`;

export const pushNotification = (messages, func) => {
  if (func) {
    func(
      messages.map(({ variant, message }) => ({
        variant,
        message,
        id: new Date().getTime(),
        dismissAfter: 'NOTIFICATION_TIMEOUT'
      }))
    );
  }
};

export const successNotification = ({ message, addNotifications }) => {
  if (!addNotifications) {
    return;
  }
  pushNotification(
    [
      {
        message,
        variant: 'success'
      }
    ],
    addNotifications
  );
};

export const unexpectedErrorNotification = (addNotifications) => {
  if (!addNotifications) {
    return;
  }
  pushNotification(
    [
      {
        message: 'UNEXPECTED_ERROR_MESSAGE',
        variant: 'error'
      }
    ],
    addNotifications
  );
};
