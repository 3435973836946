import * as React from 'react';
import { useEffect, useRef } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import './styles.css';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import logo from '../../images/Logo.svg';
import accountImage from '../../images/default_account.png';
import notificationImage from '../../images/default_notification.png';
import { useLanguageContext } from '../../contexts/languageContext';
import { useUserContext } from '../../contexts/userContext';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { getNotifications, setAsOld, setAsViewed } from '../../services/notificationsService';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroller';
import { useNotificationsContext } from '../../contexts/NotificationsContext';
import { ReactComponent as ChatIcon } from '../../images/admin icons/admin_chat.svg';
import { ReactComponent as TimeslotsIcon } from '../../images/admin icons/admin_timeslots.svg';
import { ReactComponent as HookupsIcon } from '../../images/admin icons/admin_hookups.svg';
import { ReactComponent as UsersIcon } from '../../images/admin icons/admin_users.svg';
import { ReactComponent as ReviewsIcon } from '../../images/admin icons/admin_reviews.svg';
import { ReactComponent as BellIcon } from '../../images/system icons/system_bell.svg';
import { SvgIcon } from '@mui/material';

function PageWrapper() {
  const [profileMenu, setProfileMenu] = React.useState(false);
  const [notificationsMenu, setNotificationsMenu] = React.useState(false);
  const [notifications, setNotifications] = React.useState([]);
  const [notificationsCount, setNotificationsCount] = React.useState(0);
  const [notificationsPages, setNotificationsPages] = React.useState(0);
  const [notificationsPage, setNotificationsPage] = React.useState(0);
  const { gettext } = useLanguageContext();
  const { user } = useUserContext();
  let location = useLocation();
  const scrollParentRef = useRef();
  const { unreadMessagesCount } = useNotificationsContext();

  useEffect(() => {
    getNotificationsList();
    const interval = setInterval(() => {
      getNotificationsList();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setProfileMenu(false);
  }, [location]);

  const getNotificationsList = (pageNumber) => {
    setNotificationsPage(pageNumber);
    getNotifications({ page: pageNumber }).then((result) => {
      if (pageNumber || notifications.length === 0) {
        setNotifications([...notifications, ...result.data.notifications.elements]);
      }
      setNotificationsCount(result.data.newNotificationsCount);
      setNotificationsPages(result.data.notifications.totalPages);
    });
  };

  const markAsViewed = (notification) => {
    return setAsViewed({ notificationId: notification.id }).then((r) => {
      notifications.map((notif) => {
        if (notif.date === notification.date) notif.viewed = true;
        return notif;
      });
      setNotifications([...notifications]);
    });
  };

  const clickToNavigate = (link) => {
    if (link) window.location.href = link;
  };
  const toggleMenu = () => {
    setNotificationsMenu(false);
    setProfileMenu(!profileMenu);
  };

  const toggleNotificationsMenu = () => {
    setProfileMenu(false);
    if (!notificationsMenu) {
      setAsOld().then((r) => {
        setNotificationsCount(0);
      });
    }
    setNotificationsMenu(!notificationsMenu);
  };

  const sideMenus = [
    {
      name: 'APP.SIDEBAR.MENU.TIMESLOTS',
      icon: <SvgIcon component={TimeslotsIcon} />,
      link: '/timecycles',
      matches: ['/timecycles']
    },
    {
      name: 'APP.SIDEBAR.MENU.USERS',
      icon: <SvgIcon component={UsersIcon} />,
      link: '/users',
      matches: ['/users', '/']
    },
    {
      name: 'APP.SIDEBAR.MENU.HOOKUPS',
      icon: <SvgIcon component={HookupsIcon} />,
      link: '/hookups',
      matches: ['/hookups']
    },
    {
      name: 'APP.SIDEBAR.MENU.CHAT',
      icon: <SvgIcon component={ChatIcon} />,
      link: '/chat',
      hasEvents: true,
      matches: ['/chat']
    },
    {
      name: 'APP.SIDEBAR.MENU.REVIEWS',
      icon: <SvgIcon component={ReviewsIcon} />,
      link: '/reviews',
      matches: ['/reviews']
    }
  ];
  const drawer = (
    <div>
      <List>
        {sideMenus.map((menu, index) => (
          <Link key={menu.name} to={menu.link}>
            <ListItem disablePadding>
              <ListItemButton className={menu.matches.includes(window.location.pathname) ? 'menu-button active' : 'menu-button'}>
                <ListItemIcon className={'menu-icon'}>{menu.icon}</ListItemIcon>
                <ListItemText primary={gettext(menu.name)} />
                {menu.hasEvents && unreadMessagesCount > 0 && (
                  <ListItemSecondaryAction>
                    <div className={'number-circle'}>{unreadMessagesCount}</div>
                  </ListItemSecondaryAction>
                )}
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
  );

  const menus = [
    {
      name: 'APP.NAVBAR.MENU.PROFILE',
      link: '/profile',
      matches: ['/profile', '/']
    },
    {
      name: 'APP.NAVBAR.MENU.LOGOUT',
      link: '/logout',
      matches: ['/logout']
    }
  ];

  return (
    <>
      <div className={'page-wrapper'}>
        <div className={'navbar'}>
          <div className={'top-containers logo'}>
            <img src={logo} />
          </div>
          <div className={'top-containers menus'}>
            {/*<div className={'notifications-group'}>*/}
            {/*  <div className={'profile-arrow wrap'} onClick={toggleNotificationsMenu}>*/}
            {/*    <NotificationsOutlinedIcon sx={{ fontSize: '30px', margin: '0px 20px', cursor: 'pointer' }} />*/}
            {/*    {notificationsCount && <div className={'number-circle'}>{notificationsCount}</div>}*/}
            {/*  </div>*/}
            {/*  {notificationsMenu && (*/}
            {/*    <div className={'notifications-container'} ref={scrollParentRef}>*/}
            {/*      <InfiniteScroll*/}
            {/*        pageStart={0}*/}
            {/*        loadMore={(page) => {*/}
            {/*          if (notificationsPages < notificationsPage) getNotificationsList(page);*/}
            {/*        }}*/}
            {/*        hasMore={true}*/}
            {/*        useWindow={false}*/}
            {/*      >*/}
            {/*        {notifications.length === 0 && <p className={'no-results'}>{gettext('APP.USER.NOTIFICATIONS.NO_RESULT')}</p>}*/}
            {/*        {notifications.map((notification, index) => {*/}
            {/*          return (*/}
            {/*            <div*/}
            {/*              key={notification.date + index}*/}
            {/*              className={notification.viewed ? 'notification-container curssor-pointer' : 'notification-container new-notification curssor-pointer'}*/}
            {/*              onClick={(e) => {*/}
            {/*                e.stopPropagation();*/}
            {/*                e.preventDefault();*/}
            {/*                markAsViewed(notification).then((result) => {*/}
            {/*                  if (notification.notificationCode) {*/}
            {/*                    clickToNavigate(gettext('APP.COMMON.NOTIFICATION_LINK.' + notification.notificationCode));*/}
            {/*                  }*/}
            {/*                });*/}
            {/*              }}*/}
            {/*            >*/}
            {/*              <div className={'image'}>*/}
            {/*                {notification.image && <img src={notification.image} alt='Profile' />}*/}
            {/*                {!notification.image && <img src={notificationImage} alt='Profile' />}*/}
            {/*              </div>*/}
            {/*              <div className={'notification-content curssor-pointer'}>*/}
            {/*                <div>{gettext('APP.COMMON.NOTIFICATION.' + notification.notificationCode)}</div>*/}
            {/*                <div className={'notification-date'}>*/}
            {/*                  <div className={'notification-date-container'}>*/}
            {/*                    <span>{gettext('APP.USER.NOTIFICATIONS.DATE')}</span>*/}
            {/*                    <span className={'notification-date-value'}>{moment(notification.date).format('DD.MM.YYYY')}</span>*/}
            {/*                  </div>*/}
            {/*                  <div className={'notification-date-container'}>*/}
            {/*                    <span>{gettext('APP.USER.NOTIFICATIONS.TIME')}</span>*/}
            {/*                    <span className={'notification-date-value'}>{moment(notification.date).format('HH:mm')}</span>*/}
            {/*                  </div>*/}
            {/*                </div>*/}
            {/*              </div>*/}
            {/*            </div>*/}
            {/*          );*/}
            {/*        })}*/}
            {/*      </InfiniteScroll>*/}
            {/*    </div>*/}
            {/*  )}*/}
            {/*</div>*/}
            <div className={'profile-group'} onClick={toggleMenu}>
              <div className={'profile-picture'}>{user.imageUrl ? <img src={user.imageUrl} alt='Profile' /> : <img src={accountImage} alt='Profile' />}</div>
              <div className={'profile-arrow wrap'}>
                <span className={profileMenu ? 'arrow' : 'arrow active'}>
                  <span></span>
                  <span></span>
                </span>
              </div>
              {profileMenu ? (
                <div className={'profile-container'}>
                  <header>
                    <div className={'name'}>
                      {user.firstName} {user.lastName}
                    </div>
                  </header>
                  <section>
                    <ul>
                      {menus.map((menu) => {
                        return (
                          <Link key={menu.name} to={menu.link}>
                            <li className={menu.matches.includes(window.location.pathname) ? 'current' : ''}>{gettext(menu.name)}</li>
                          </Link>
                        );
                      })}
                    </ul>
                  </section>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className={'content'}>
          <div className={'sidemenu'}>{drawer}</div>
          <div className={'page-content'}>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}

export default PageWrapper;
