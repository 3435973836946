import React, { useEffect, useRef } from 'react';
import Select, { createFilter } from 'react-select';
import AsyncSelect from '../../Field/AsyncSelect';
import { useLanguageContext } from '../../../contexts/languageContext';

export default function MultiSelectField({ input, meta, label, required, loadOptions, ...others }) {
  const { gettext } = useLanguageContext();
  const style = (meta.error || meta.submitError) && meta.touched && !others.disabled ? { color: 'red' } : {};
  const ref = useRef(null);

  useEffect(() => {
    ref.current && ref.current.setCustomValidity(meta.error && meta.touched ? gettext(meta.error) : '');
  });

  return (
    <div className='input-group' style={{ ...others.style, opacity: others.disabled ? 0.5 : 1 }}>
      <label htmlFor={others.id} style={style}>
        {label} {required && !others.noasterisk && <span className='is-required'>*</span>}
      </label>
      {loadOptions ? (
        <AsyncSelect
          loadOptions={loadOptions}
          input={input}
          {...others}
          isSearchable={others.readOnly ? false : others.isSearchable}
          menuIsOpen={others.readOnly ? false : undefined}
        />
      ) : (
        <Select
          className={`multi-select-input ${others.isSearchable ? 'searchable' : ''}`}
          classNamePrefix='react-select'
          isMulti
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          maxMenuHeight={200}
          isDisabled={others.disabled}
          isSearchable={false}
          {...input}
          {...others}
          filterOption={createFilter({ ignoreAccents: false })}
          onChange={(...args) => {
            input.onChange(...args);
            others.onChange && others.onChange(...args);
          }}
        />
      )}
      {/* The hidden input is needed for displaying the error messages in field validation
      https://github.com/JedWatson/react-select/issues/3140 */}
      <input ref={ref} tabIndex={-1} autoComplete='off' style={{ opacity: 0, height: 0, padding: 0 }} defaultValue={input.value} />
    </div>
  );
}
