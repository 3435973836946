import React, { useEffect, useRef } from 'react';
import { useLanguageContext } from '../../../contexts/languageContext';

export default function TextArea({ input, meta, label, info, required, ...others }) {
  const { gettext, gettextPlaceholder } = useLanguageContext();
  const style = (meta.error || meta.submitError) && meta.touched && !others.readOnly ? { color: 'red' } : {};
  const styleBorder = (meta.error || meta.submitError) && meta.touched && !others.readOnly ? { border: '1px solid red' } : {};
  const fieldRef = useRef(null);

  useEffect(() => {
    fieldRef.current &&
      fieldRef.current.setCustomValidity(
        meta.error && meta.touched ? (meta.error.text && meta.error.values ? gettextPlaceholder(meta.error.text, meta.error.values) : gettext(meta.error)) : ''
      );
  });

  return (
    <div className='input-group textarea' style={{ opacity: others.disabled ? 0.5 : 1, position: 'relative' }}>
      <label htmlFor={others.id} style={style}>
        {label} {required && !others.readOnly && !others.noasterisk && <span className='is-required'>*</span>}
      </label>
      {info && <span style={{ fontSize: '14px', textAlign: 'left' }}>{info}</span>}
      <textarea
        ref={fieldRef}
        {...input}
        type={input.type || 'text'}
        style={(styleBorder ? styleBorder : '', { resize: others.readOnly ? 'none' : '' })}
        {...others}
        onChange={(e) => {
          input.onChange(e);
          others.onChange && others.onChange(e);
        }}
        onBlur={(e) => {
          e.target.value = e.target.value.trim();
          input.onChange(e.target.value);
          others.onChange && others.onChange(e.target.value);
        }}
      />
    </div>
  );
}
