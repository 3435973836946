import React, { useEffect, useState } from 'react';
import './styles.css';
import ConversationView from '../../Conversation/View';
import { useConversationsContext } from '../../../../contexts/ConversationsContext';
import { useMessagesContext } from '../../../../contexts/MessagesContext';
import { useUnreadMessagesContext } from '../../../../contexts/UnreadMessagesContext';
import { useParticipantsContext } from '../../../../contexts/ParticipantsContext';
import { useTypingDataContext } from '../../../../contexts/TypingDataContext';
import { getTypingMessage, unexpectedErrorNotification } from '../../../../utils/chat/helpers';
import { getSdkConversationObject } from '../../../../utils/chat/conversationsObjects';
import { Divider, Grid, List, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { Search } from '@mui/icons-material';
import moment from 'moment';

/**
 *
 *
 *  HELPER FUNCTIONS
 *
 *
 */

function getLastMessage(messages, typingData) {
  if (messages === undefined || messages === null) {
    return 'Loading...';
  }
  if (typingData.length) {
    return getTypingMessage(typingData);
  }
  if (messages.length === 0) {
    return 'No messages';
  }
  return messages[messages.length - 1].body || 'Media message';
}

function isMyMessage(messages) {
  if (messages === undefined || messages === null || messages.length === 0) {
    return false;
  }
  return messages[messages.length - 1].author === localStorage.getItem('username') ? messages[messages.length - 1] : false;
}

async function updateCurrentConvo(setSid, convo, updateParticipants) {
  setSid(convo.sid);

  try {
    const participants = await getSdkConversationObject(convo).getParticipants();
    updateParticipants(participants, convo.sid);
  } catch {
    return Promise.reject('UNEXPECTED_ERROR_MESSAGE');
  }
}

function setUnreadMessagesCount(currentconvoSid, convoSid, unreadMessages, updateUnreadMessages) {
  if (currentconvoSid == convoSid && unreadMessages[convoSid] !== 0) {
    updateUnreadMessages(convoSid, 0);
    return 0;
  }
  if (currentconvoSid == convoSid) {
    return 0;
  }
  return unreadMessages[convoSid];
}

/**
 *
 *
 *  END HELPER FUNCTIONS
 *
 *
 */
function ChatsList({ client, conversation, changeConversationHandler }) {
  const { conversations } = useConversationsContext();
  const { messages } = useMessagesContext();
  const { unreadMessages } = useUnreadMessagesContext();
  const { participants, setParticipants } = useParticipantsContext();
  const { typingData } = useTypingDataContext();
  const [sid, setSid] = useState(null);
  const [search, setSearch] = useState('');
  const [filteredConversations, setFilteredConversations] = useState([]);

  useEffect(() => {
    let foundConversations = conversations;
    if (search) {
      foundConversations = [];
      conversations.map((conv) => {
        let partics = [];
        if (participants[conv.sid]) {
          participants[conv.sid].map((part) => {
            partics.push(part.state.identity);
          });
        }
        partics.map((part) => {
          if (conv[part].fullName.toLowerCase().indexOf(search.toLowerCase()) > -1) {
            foundConversations.push(conv);
          }
        });
      });
    }

    foundConversations.map((conv) => {
      if (messages[conv.sid]) conv.lastMessageDate = messages[conv.sid].length ? moment(messages[conv.sid][messages[conv.sid].length - 1].dateCreated) : '';
    });
    foundConversations = foundConversations.sort((a, b) => {
      return a.lastMessageDate < b.lastMessageDate ? 1 : -1;
    });

    setFilteredConversations(foundConversations);

    setSid(conversation ? conversation.sid : null);
  }, [conversation, search, conversations, participants, messages]);

  const updateCurrentConversation = (sid) => {
    changeConversationHandler(sid);
  };

  const updateUnreadMessages = (channelSid, unreadCount) => {
    // console.log("updateUnreadMessages ", channelSid, unreadCount);
  };

  const setLastReadIndex = (index) => {
    // conversation.advanceLastReadMessageIndex(message.index);
    console.log('setLastReadIndex ', index);
  };
  const updateParticipants = (partics, sid) => {
    let parts = participants;
    parts[sid] = partics;
    setParticipants({ ...parts });
  };
  const addNotifications = (notifications) => {
    console.log('addNotifications ', notifications);
  };

  return (
    <div>
      <Grid item xs={12} className={'chat-search'}>
        <TextField
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          id='outlined-basic-email'
          variant='outlined'
          margin='normal'
          size='small'
          sx={{
            m: '8px',
            ml: '3%',
            width: '94%'
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Search />
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Divider />
      <List sx={{ padding: 0 }} className={'chat-list'}>
        {filteredConversations &&
          filteredConversations.length > 0 &&
          filteredConversations.map((convo) => (
            <ConversationView
              key={convo.sid}
              convoId={convo.sid}
              setSid={updateCurrentConversation}
              currentConvoSid={sid}
              lastMessage={getLastMessage(messages[convo.sid], typingData[convo.sid] || []) || ''}
              messages={messages[convo.sid]}
              typingInfo={typingData[convo.sid] ?? []}
              myMessage={isMyMessage(messages[convo.sid])}
              unreadMessagesCount={setUnreadMessagesCount(sid, convo.sid, unreadMessages, updateUnreadMessages)}
              updateUnreadMessages={updateUnreadMessages}
              participants={participants[convo.sid] ?? []}
              convo={convo}
              onClick={async () => {
                try {
                  setLastReadIndex(convo.lastReadMessageIndex ?? -1);
                  await updateCurrentConvo(updateCurrentConversation, convo, updateParticipants);
                  //update unread messages
                  updateUnreadMessages(convo.sid, 0);
                  //set messages to be read
                  const lastMessage = messages[convo.sid].length && messages[convo.sid][messages[convo.sid].length - 1];
                  if (lastMessage && lastMessage.index !== -1) {
                    await getSdkConversationObject(convo).advanceLastReadMessageIndex(lastMessage.index);
                  }
                } catch {
                  unexpectedErrorNotification(addNotifications);
                }
              }}
            />
          ))}
      </List>
    </div>
  );
}

export default ChatsList;
