import React, { useEffect, useState } from 'react';
import './styles.css';
import { Client } from '@twilio/conversations';
import ChatsList from '../List';
import { Grid, Paper } from '@mui/material';
import { useConversationsContext } from '../../../../contexts/ConversationsContext';
import { useMessagesContext } from '../../../../contexts/MessagesContext';
import { useParticipantsContext } from '../../../../contexts/ParticipantsContext';
import { useLocation } from 'react-router-dom';
import { addConversation, addParticipant } from '../../api';
import { useUserContext } from '../../../../contexts/userContext';
import { getUsersByUsersId } from '../../../../services/userService';
import ConversationContainer from '../../Conversation/Container';
import { conversationsMap, messagesMap } from '../../../../utils/chat/conversationsObjects';

function ChatContainer({ token, getToken }) {
  const [client, setClient] = useState();
  const [connectionState, setConnectionState] = useState();
  const [activeConversation, setActiveConversation] = useState();
  const { conversations, setConversations } = useConversationsContext();
  const { messages, setMessages } = useMessagesContext();
  const { participants, setParticipants } = useParticipantsContext();
  const { user, setUser } = useUserContext();
  const params = new URLSearchParams(useLocation().search);

  //Catch All participants in Conversations - we need them to load users info from db
  async function loadAllParticipants(convs) {
    let promises = [];
    let userIds = [];
    if (params.get('chatId')) {
      userIds.push(params.get('chatId'));
    }
    convs.map((conv) => {
      promises.push(
        conv.getParticipants().then((res) => {
          res.map((r) => {
            conv[r.identity] = r;
            if (r.identity !== user.id) {
              userIds.push(r.identity);
            }
          });
        })
      );
    });
    return await Promise.all(promises).then(() => {
      return userIds;
    });
  }

  async function loadAllMessages(convs) {
    let promises = [];
    let loadedMessages = {};
    convs.map((conv) => {
      promises.push(
        conv.getMessages().then((result) => {
          loadedMessages[conv.sid] = result.items;
        })
      );
    });

    return await Promise.all(promises).then(() => {
      setMessages({ ...loadedMessages });
      return loadedMessages;
    });
  }

  useEffect(() => {
    if (!client) {
      const client = new Client(token);
      setClient(client);
    } else {
      client.on('conversationJoined', (conversation) => {
        setActiveConversation(conversation);

        if (conversation.status === 'joined') {
          conversation.getParticipants().then((result) => {
            if (typeof participants[conversation.sid] === 'undefined') participants[conversation.sid] = [];
            participants[conversation.sid] = result;
            setParticipants({ ...participants });
          });

          conversation.getMessages().then((result) => {
            if (typeof messages[conversation.sid] === 'undefined') messages[conversation.sid] = [];
            messages[conversation.sid] = result.items;
            setMessages({ ...messages });
          });
        }

        // Here we can set listeners for typing

        // conversation.on("typingStarted", (participant) => {
        //     handlePromiseRejection(
        //         () =>
        //             updateTypingIndicator(participant, conversation.sid, startTyping),
        //         addNotifications
        //     );
        // });
        //
        // conversation.on("typingEnded", (participant) => {
        //     handlePromiseRejection(
        //         () => updateTypingIndicator(participant, conversation.sid, endTyping),
        //         addNotifications
        //     );
        // });
        //
        // handlePromiseRejection(async () => {
        //     if (conversation.status === "joined") {
        //         const result = await getConversationParticipants(conversation);
        //         updateParticipants(result, conversation.sid);
        //
        //         const messages = await conversation.getMessages();
        //         upsertMessages(conversation.sid, messages.items);
        //         loadUnreadMessagesCount(conversation, updateUnreadMessages);
        //     }
        // }, addNotifications);
      });
      client.on('conversationRemoved', (conversation) => {
        console.log('conversationRemoved');
        setActiveConversation(null);

        //remove conversation from the conversations
        // removeConversation(conversation.sid);
        // updateParticipants([], conversation.sid);
      });
      client.on('messageAdded', (message) => {
        console.log('messageAdded');
        upsertMessage(message);
        // if (message.author === user.id) {
        //     clearAttachments(message.conversation.sid, "-1");
        // }
      });
      client.on('participantLeft', (participant) => {
        console.log('participantLeft');
        //some logic for group chat here
      });
      client.on('participantUpdated', (event) => {
        console.log('participantUpdateddd');
        //some logic for group chat here
      });
      client.on('participantJoined', (participant) => {
        console.log('participantJoined');
        //some logic for group chat here
      });
      client.on('conversationUpdated', ({ conversation }) => {
        setActiveConversation(conversation);
      });
      client.on('messageUpdated', ({ message }) => {
        upsertMessage(message);
      });
      client.on('messageRemoved', (message) => {
        console.log('messageRemoved');
        //some logic to remove message in conversation
      });
      client.on('pushNotification', (event) => {
        console.log('pushNotification');
        // // @ts-ignore
        // if (event.type != "twilio.conversations.new_message") {
        //     return;
        // }
        //
        // if (Notification.permission === "granted") {
        //     showNotification(event);
        // } else {
        //     console.log("Push notification is skipped", Notification.permission);
        // }
      });
      client.on('tokenAboutToExpire', () => {
        getToken();
      });
      client.on('tokenExpired', () => {
        getToken();
      });
      client.on('connectionStateChanged', (state) => {
        setConnectionState(state);
      });

      getSubscribedConversations(client).then((result) => {
        setConversations(result);
      });
    }

    return () => {
      client?.removeAllListeners();
    };
  }, [client]);

  /**
   *
   *
   *  HELPER FUNCTIONS
   *
   *
   *
   */

  // Get User all conversations
  async function getSubscribedConversations(client) {
    let subscribedConversations = await client.getSubscribedConversations();
    let conversations = subscribedConversations.items;

    while (subscribedConversations.hasNextPage) {
      subscribedConversations = await subscribedConversations.nextPage();
      conversations = [...conversations, ...subscribedConversations.items];
    }
    // checkForConversations(conversations);

    let participants = await loadAllParticipants(conversations);
    let usersDB = await getUsersByUsersId(participants);
    let users = {};
    let hasConversation = false;
    usersDB.data.map((user) => {
      users[user.id] = user;
    });
    await loadAllMessages(conversations);

    conversations.map((conv) => {
      let secondParticipantId = conv.friendlyName.replace('_', '').replace(user.id, '');
      conv[secondParticipantId] = users[secondParticipantId];
      conv[user.id] = {
        id: user.id,
        imageUrl: user.imageUrl,
        fullName: user.fullName,
        username: user.username,
        host: true
      };

      if (conv.friendlyName.indexOf(params.get('chatId')) > -1) {
        hasConversation = true;
        setActiveConversation(conv);
      }
      conversationsMap.set(conv.sid, conv);
    });
    if (params.get('chatId') && !hasConversation) {
      let newConversation = await addConversation(params.get('chatId') + '_' + user.id, client);
      await addParticipant(params.get('chatId'), params.get('chatId'), true, newConversation);
      let secondParticipantId = newConversation.friendlyName.replace('_', '').replace(user.id, '');
      newConversation[secondParticipantId] = users[secondParticipantId];
      newConversation[user.id] = {
        id: user.id,
        imageUrl: user.imageUrl,
        fullName: user.fullName,
        username: user.username,
        host: true
      };
      conversations.push(newConversation);
      setActiveConversation(newConversation);
      conversationsMap.set(newConversation.sid, newConversation);
    }

    return conversations;
  }

  function upsertMessage(message) {
    //if message is from the current conversation
    if (activeConversation && activeConversation.sid === message.conversation.sid) {
      console.log(message.index);
      message.conversation.advanceLastReadMessageIndex(message.index);
    }

    const existingMessages = messages[message.conversation.sid] ?? [];

    for (const mess of [message]) {
      messagesMap.set(mess.sid, mess);
    }
    messages[message.conversation.sid] = [...existingMessages, ...[message]];
    setMessages({ ...messages });

    //set counter to unread messages for conversation
    getUnreadMessagesCount(message.conversation).then((result) => {
      console.log('getUnreadMessagesCount ', result);
      //some logic for setting unread message count to the conversation;
    });
  }

  async function getUnreadMessagesCount(convo) {
    let count = 0;

    try {
      count = (await convo.getUnreadMessagesCount()) ?? (await convo.getMessagesCount());
    } catch (e) {
      console.error('getUnreadMessagesCount threw an error', e);
    }

    return count;
  }

  const changeConversation = (sid) => {
    let cons = conversations.filter((conv) => {
      if (conv.sid === sid) return conv;
    });
    setActiveConversation(cons[0]);
  };

  /**
   *
   *
   *  END HELPER FUNCTIONS
   *
   *
   */

  return (
    <div className={'chat-table-wrapper'}>
      {/*<AlertsView />*/}
      {/*<Notifications />*/}
      <Grid container component={Paper} className={'chatSection'}>
        <Grid item xs={3} className={'borderRight500'}>
          <ChatsList client={client} conversation={activeConversation} changeConversationHandler={changeConversation} />
        </Grid>
        <Grid item xs={9} className={'message-section'}>
          <ConversationContainer conversation={activeConversation} client={client} />
        </Grid>
      </Grid>
    </div>
  );
}

export default ChatContainer;
