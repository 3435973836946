import React, { useState } from 'react';
import { useLanguageContext } from '../../contexts/languageContext';
import Dropzone from './Dropzone';
import ProgressBar from './ProgressBar';
import Error from '../../components/Form/Error';
import { removeItem } from '../../utils/storage';

export function handleErrorMessage(err) {
  const handleMessage = (data) => (data.message && isNaN(data.message.trim()) ? data.message : 'Неустановена грешка');

  return err.response && err.response.data
    ? err.response.data.errors && err.response.data.errors.length
      ? err.response.data.errors[0].message
      : handleMessage(err.response.data)
    : handleMessage(err);
}

export default function FileUpload({ fieldName, api, onSuccess, extraField, getAditionalData, customClasses, accept, user }) {
  const [successfullUploaded, setSuccessfullUploaded] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(false);
  const [error, setError] = useState();
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const { gettext } = useLanguageContext();
  const disableSubmitButton = !file || successfullUploaded || loading;
  const RESULTS_PER_PAGE = 10;

  const onFileAdded = (file) => {
    if (file.size > 5e6) {
      setError(gettext('APP.COMMON.PROFILE.PICTURE_UPLOAD'));
      return false;
    } else {
      setError(null);
    }
    const data = new FormData();
    data.append(fieldName, file);
    if (extraField) {
      data.append(extraField.key, extraField.value);
    }
    setFile(data);
    setUploadProgress();
    setSuccessfullUploaded(false);
  };

  const updateProgressBarValue = (percent) => setUploadProgress(percent);

  const uploadFile = (file) => {
    let dataFields;
    if (typeof getAditionalData !== 'undefined') {
      dataFields = getAditionalData();
      if (!dataFields) {
        return;
      }
      dataFields.forEach((field) => {
        file.append(field.key, field.value);
      });
    }
    setLoading(true);
    setUploadProgress();
    setError(null);
    api(file, updateProgressBarValue, user)
      .then(({ data }) => {
        setError(false);
        setLoading(false);
        setSuccessfullUploaded(true);
        onSuccess && onSuccess(data);
        removeItem('askForImage');
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errors && e.response.data.errors.length) {
          console.log('aaa');
          setError(getErrorMessage(e.response.data));
        } else {
          console.log(e);
          setError(handleErrorMessage(e));
        }
        setLoading(false);
      });
  };

  const getErrorMessage = (err) => {
    let errList = [];

    let hasProblem = false;
    if (Array.isArray(err.errors)) {
      err.errors.forEach((errElem) => {
        errList.push({
          message: errElem.message,
          lpn: errElem.lpn,
          country: errElem.country
        });
      });
    }
    if (hasProblem) {
      return err;
    }
    setError(errList);
    return errList;
  };
  const resetFile = () => {
    setFile();
    setUploadProgress();
    setSuccessfullUploaded(false);
  };

  return (
    <div className={`upload-file-container ${customClasses}`}>
      <Dropzone onFileAdded={onFileAdded} loading={loading} accept={accept} />

      {file && (
        <div className='progress-wrapper'>
          <ProgressBar file={file.get(fieldName).name} progress={uploadProgress} resetFile={resetFile} />
        </div>
      )}

      <div className='actions-wrapper'>
        <button
          onClick={() => (disableSubmitButton ? {} : uploadFile(file))}
          className={`button button-primary ${disableSubmitButton ? 'button-upload-disabled' : ''}`}
        >
          {gettext('APP.USERS.FILE.UPLOAD_FILE')}
        </button>
      </div>
      {error && !Array.isArray(error) && <Error text={error} />}
    </div>
  );
}
