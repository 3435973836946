export const conversationsMap = new Map();
export const messagesMap = new Map();
// export const mediaMap = new Map();
// export const participantsMap = new Map();

const capitalize = (str) => `${str[0].toUpperCase()}${str.substring(1)}`;

const getSdkObject = (objectMap, sid, type) => {
  if (!objectMap.size) return null;
  const sdkObject = objectMap.get(sid);

  if (!sdkObject) {
    throw new Error(`${capitalize(type)} with SID ${sid} was not found.`);
  }

  return sdkObject;
};

export const getSdkConversationObject = (reduxConversation) => getSdkObject(conversationsMap, reduxConversation.sid, 'conversation');

export const getSdkMessageObject = (reduxMessage) => getSdkObject(messagesMap, reduxMessage.sid, 'message');

// export const getSdkMediaObject = (reduxMedia) =>
//     getSdkObject(mediaMap, reduxMedia.sid, "media");
//
// export const getSdkParticipantObject = (
//     reduxParticipant
// ) =>
//     getSdkObject(participantsMap, reduxParticipant.sid, "participant");
