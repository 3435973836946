import React, { useEffect, useState } from 'react';
import './styles.css';
import { getChatToken } from '../../services/chatService';
import ChatContainer from './Chat/Container';
import { Grid } from '@mui/material';
import { useLanguageContext } from '../../contexts/languageContext';

function Chat() {
  const [token, setToken] = useState();
  const { gettext } = useLanguageContext();

  useEffect(() => {
    getNewChatToken();
  }, []);

  const getNewChatToken = () => {
    getChatToken().then((result) => {
      setToken(result.data);
    });
  };

  return (
    <>
      <Grid container className='chat-page-container'>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <h1 className={'chat-title'}>{gettext('APP.ADMIN.CHAT.TITLE')}</h1>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {token && <ChatContainer token={token} getToken={getNewChatToken} />}
        </Grid>
      </Grid>
    </>
  );
}

export default Chat;
