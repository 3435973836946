import React, { useEffect, useRef, useState } from 'react';
import { useLanguageContext } from '../../../contexts/languageContext';
import loader from '../../../images/loader.gif';

export default function TextField({ input, meta, label, info, required, children, noLabel, btnClick, btnIconCls, btnTitle, preventUndefined, ...others }) {
  const { gettext, gettextPlaceholder } = useLanguageContext();
  const style = (meta.error || meta.submitError) && meta.touched && !others.readOnly ? { color: 'red' } : {};
  const styleBorder = (meta.error || meta.submitError) && meta.touched && !others.readOnly ? { border: '1px solid red' } : {};
  const fieldRef = useRef(null);
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    fieldRef.current &&
      fieldRef.current.setCustomValidity(
        meta.error && meta.touched ? (meta.error.text && meta.error.values ? gettextPlaceholder(meta.error.text, meta.error.values) : gettext(meta.error)) : ''
      );
  });

  return noLabel ? (
    <input
      ref={fieldRef}
      {...input}
      type={input.type || 'text'}
      {...others}
      onChange={(e) => {
        const val = e.target.value === '' && preventUndefined ? ' ' : e.target.value;
        input.onChange(val);
        others.onChange && others.onChange(val);
      }}
      onBlur={(e) => {
        e.target.value = e.target.value.trim();
        const val = e.target.value === '' && preventUndefined ? ' ' : e.target.value;
        input.onChange(val);
        others.onChange && others.onChange(val);
      }}
      style={{ ...others.style, ...styleBorder }}
    />
  ) : (
    <div className='input-group' style={{ opacity: others.disabled ? 0.5 : 1, position: 'relative' }}>
      <label htmlFor={others.id} style={style}>
        {label} {required && !others.readOnly && !others.noasterisk && <span className='is-required'>*</span>}
      </label>
      {info && <span style={{ fontSize: '14px', textAlign: 'left' }}>{info}</span>}
      <input
        ref={fieldRef}
        {...input}
        type={input.type || 'text'}
        {...others}
        onChange={(e) => {
          input.onChange(e);
          others.onChange && others.onChange(e);
        }}
        onBlur={(e) => {
          e.target.value = e.target.value.trim();
          input.onChange(e.target.value);
          others.onChange && others.onChange(e.target.value);
        }}
        style={{ ...others.style, ...styleBorder }}
      />
      {meta.error && meta.touched && (
        <span style={{ marginTop: '10px', marginLeft: '5px' }}>
          {meta.error.text ? (meta.error.values ? gettextPlaceholder(meta.error.text, meta.error.values) : gettext(meta.error)) : gettext(meta.error)}
        </span>
      )}
      {btnClick && !others.readOnly && (
        <button
          title={btnTitle}
          type='button'
          disabled={others.disabled}
          onClick={() => {
            if (!btnLoading) {
              setBtnLoading(true);
              const result = btnClick(input.value);
              Promise.resolve(result).then(() => setBtnLoading(false));
            }
          }}
          className='inside-button'
        >
          {btnLoading ? <img src={loader} alt='' /> : <i className={btnIconCls || 'icon icon-easy'} />}
        </button>
      )}
      {children}
    </div>
  );
}
