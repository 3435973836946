import React, { useEffect, useState } from 'react';
import './styles.css';
import { useConversationsContext } from '../../../../contexts/ConversationsContext';
import { useMessagesContext } from '../../../../contexts/MessagesContext';
import { useParticipantsContext } from '../../../../contexts/ParticipantsContext';
import { useTypingDataContext } from '../../../../contexts/TypingDataContext';
import ConversationDetails from '../Details';
import MessagesBox from '../../Messages/MessagesBox';
import MessageInputField from '../../Messages/MessageInputField';
import { Divider } from '@mui/material';
import { messagesMap } from '../../../../utils/chat/conversationsObjects';

function ConversationContainer({ conversation, client, ...props }) {
  const { loadingStatus, lastReadIndex } = useConversationsContext();
  const { messages, setMessages } = useMessagesContext();
  const { participants } = useParticipantsContext();
  const { typingData } = useTypingDataContext();
  const [sid, setSid] = useState(null);

  useEffect(() => {
    setSid(typeof conversation !== 'undefined' ? conversation.sid : null);
  }, [conversation]);

  const pushMessages = (channelSid, messagesToAdd) => {
    const existingMessages = messages[channelSid] ?? [];

    for (const message of messagesToAdd) {
      messagesMap.set(message.sid, message);
    }
    messages[channelSid] = [...existingMessages, ...messagesToAdd];
    setMessages({ ...messages });
  };

  return (
    <>
      {sid && conversation && client ? (
        <>
          <div>
            <ConversationDetails convoSid={sid} convo={conversation} participants={participants} />
            <Divider />
          </div>
          <div>
            <MessagesBox
              key={sid}
              convoSid={sid}
              convo={conversation}
              upsertMessage={pushMessages}
              client={client}
              messages={messages[sid]}
              loadingState={loadingStatus}
              participants={participants[sid]}
              lastReadIndex={lastReadIndex}
            />
            <Divider />
            <MessageInputField convoSid={sid} client={client} messages={messages[sid]} convo={conversation} typingData={typingData} />
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              height: '100%',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'center',
              fontFamily: 'Inter',
              lineHeight: '20px'
            }}
          >
            Select a conversation on the left to get started.
          </div>
        </>
      )}
    </>
  );
}

export default ConversationContainer;
