import React from 'react';
import { useLanguageContext } from '../../../contexts/languageContext';

export default function Error({ text, id, className }) {
  const { gettext } = useLanguageContext();
  let classes = className ? className : 'text-center form-error-msg ';
  return (
    <div key={id} className={classes}>
      {' '}
      {text || gettext('Неустановена грешка')}{' '}
    </div>
  );
}
