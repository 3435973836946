import * as StorageUtil from './storage';

const USER_KEY = 'user';

export function clearUser() {
  StorageUtil.setItem(USER_KEY, '{}');
}

export function getUser() {
  return JSON.parse(StorageUtil.getItem(USER_KEY) || '{}');
}

export function setUser(user) {
  StorageUtil.setItem(USER_KEY, JSON.stringify(user));
}

// const USER_ROLES = [
//     'ROLE_USER',
//     'ROLE_ADMIN'
// ];

export function userHasRole(roleName) {
  const userRole = getUser().roles;

  if (!userRole) {
    return;
  }
  return !!userRole.find((role) => role.code === roleName);
}
