const ENV = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV.toLowerCase() : 'dev';

const CONF = {
  dev: {
    BACKEND_URL: 'https://dev-services.saleshookup.com',
    GOOGLE_API_KEY: 'AIzaSyDwQsXIJ1AQs-Thj2AR3Nq9YyAi2O1S4j4'
  },
  stg: {
    BACKEND_URL: 'https://stg-services.saleshookup.com',
    GOOGLE_API_KEY: 'AIzaSyDwQsXIJ1AQs-Thj2AR3Nq9YyAi2O1S4j4'
  },
  prod: {
    BACKEND_URL: 'https://services.saleshookup.com',
    GOOGLE_API_KEY: 'AIzaSyDwQsXIJ1AQs-Thj2AR3Nq9YyAi2O1S4j4'
  },
  loc: {
    BACKEND_URL: 'http://localhost:8060',
    GOOGLE_API_KEY: 'AIzaSyDwQsXIJ1AQs-Thj2AR3Nq9YyAi2O1S4j4'
  }
};

if (!CONF[ENV]) {
  // here we break the app because the environment variables are wrong
  throw new Error(`Missing config ${ENV}!`);
}

export const ENVIRONMENTS = CONF[ENV];
