import React, { useContext, useState } from 'react';

const NotificationsContext = React.createContext();

export const useNotificationsContext = () => useContext(NotificationsContext);

export function NotificationsProvider({ children }) {
  const [notifications, setNotificationsToState] = useState([]);
  const [askToRegister, setAskToRegister] = useState(true);

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        setNotifications: (notifications) => {
          setNotificationsToState(notifications);
        },
        unreadMessagesCount: 0
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
}
