import { Outlet } from 'react-router-dom';
import './App.css';
import ErrorBoundary from '../ErrorBoundary';
import MasterProvider from '../../contexts';
import { createTheme, ThemeProvider } from '@mui/material';
import MontserratRegular from '../../fonts/Montserrat/Montserrat-Regular.ttf';

const theme = createTheme({
  typography: {
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
    formLabels: {
      fontSize: '12px'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: "Montserrat";
          src: url('./fonts/Montserrat/Montserrat-Regular.ttf') format("opentype");
          font-weight: 400;
          font-style: normal;
          font-display: swap;
          src: local('Montserrat'), local('Montserrat-Regular'), url(${MontserratRegular}) format('opentype');
        }
      `
    }
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <MasterProvider>
          <Outlet />
        </MasterProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
