import React, { useEffect, useState } from 'react';
import './styles.css';
import TextField from '../Form/TextField';
import { Grid } from '@mui/material';
import { useLanguageContext } from '../../contexts/languageContext';
import { useUserContext } from '../../contexts/userContext';
import { Navigate, useOutletContext } from 'react-router-dom';
import Illustrations from '../../images/Illustrations.svg';
import { Field, Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { getRememberMe, setRememberMe } from '../../utils/storage';
import { required } from '../../utils/form';
import LoadingBtn from '../LoadingButton';
import Error from '../Form/Error';
import { getUserDetails, login } from '../../services/userService';
import logo from '../../images/Logo.svg';

function Login() {
  // eslint-disable-next-line
  const [hasAdminAccess, setHasAdminAccess] = useState(false);
  const [background, setBackground] = useOutletContext();
  const { gettext } = useLanguageContext();

  const { user, setUser } = useUserContext();

  const getStartRoute = () => {
    return '/users';
  };

  useEffect(() => {
    setBackground(Illustrations);
    // eslint-disable-next-line
  }, []);

  const validate = (values) => undefined;

  const onSubmit = (values) => {
    setRememberMe(values.refund);
    return login(values)
      .then((response) => {
        setUser({ token: response.data.token });
        return getUserDetails().then((res) => {
          res.data.roles.map((role) => {
            if (role.code === 'ROLE_ADMIN') {
              setHasAdminAccess(true);
            }
          });
          setUser({ token: response.data.token, ...res.data });
        });
      })
      .catch((err) => {
        return Promise.resolve({ [FORM_ERROR]: err && err.data && err.data.code ? err.data.code : 'GENERAL' });
      });
  };
  return (
    <>
      {user.username && !hasAdminAccess && <Navigate to={'logout'} />}
      {user.username ? (
        <Navigate to={getStartRoute()} />
      ) : (
        <Grid container justifyContent='center'>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='title'>
            <img src={logo} />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='title'>
            {gettext('APP.USERS.LOGIN.ADMIN_TITLE')}
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Form
              onSubmit={onSubmit}
              validate={validate}
              initialValues={{ refund: getRememberMe() }}
              render={({ handleSubmit, submitError, submitting }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'form-margin'}>
                      <Field
                        component={TextField}
                        name='username'
                        id='username'
                        label={gettext('APP.USERS.LOGIN_FORM.USERNAME_LABEL')}
                        placeholder={gettext('APP.USERS.LOGIN_FORM.USERNAME_PLACEHOLDER')}
                        type='text'
                        validate={required}
                        required
                        noasterisk='true'
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'form-margin'}>
                      <Field
                        component={TextField}
                        name='password'
                        id='password'
                        label={gettext('APP.USERS.LOGIN_FORM.PASSWORD_LABEL')}
                        placeholder={gettext('APP.USERS.LOGIN_FORM.PASSWORD_PLACEHOLDER')}
                        type='password'
                        validate={required}
                        required
                        noasterisk='true'
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      {submitError && <Error text={gettext('APP.USER.LOGIN_ERROR.' + submitError)} />}
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'form-margin'}>
                      <LoadingBtn loading={submitting} text={gettext('APP.USERS.LOGIN_FORM.LOGIN')} className={'submit-button'} />
                    </Grid>
                  </form>
                );
              }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default Login;
