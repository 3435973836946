import React, { useEffect, useState } from 'react';
import { useUserContext } from '../../contexts/userContext';
import { Navigate } from 'react-router-dom';
import { getRememberedItem } from '../../utils/storage';
import { getUserDetails } from '../../services/userService';
import loader from '../../images/loader.gif';

export default function ProtectedComponent({ children }) {
  const { user, setUser } = useUserContext();
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (!user.username) {
      if (getRememberedItem('RTNA')) {
        getUserDetails().then((res) => {
          setUser({ ...user, ...res.data });
          setLoaded(true);
        });
      } else {
        setLoaded(true);
      }
    } else {
      setLoaded(true);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {loaded ? (
        user.username ? (
          children
        ) : (
          <Navigate to={'/login'} />
        )
      ) : (
        <div className='map-loader'>
          <img src={loader} alt={''} />
        </div>
      )}
    </>
  );
}
