import { downloadGet, get, post, postFile, put, remove } from '../utils/api';
import { BASE_URL } from '../utils/consts';

export function login(params) {
  return post(`${BASE_URL}/auth/admin/login`, params);
}

export function getUserDetails(userId) {
  let url = `${BASE_URL}/user/details`;
  if (userId) {
    url = `${BASE_URL}/admin/user/${userId}`;
  }
  return get(url);
}

export function getToken(params) {
  return post(`${BASE_URL}/auth/refreshToken`, params);
}

export function uploadPhoto(params, updateProgressBarValue, userId) {
  let url = `${BASE_URL}/user/upload-avatar`;
  if (userId) {
    url = `${BASE_URL}/admin/user/upload-avatar/${userId}`;
  }
  return postFile(url, params, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: (progressEvent) => {
      if (progressEvent.lengthComputable) {
        const percent = (progressEvent.loaded / progressEvent.total) * 100;
        updateProgressBarValue(percent);
      }
    }
  });
}

export function deletePhoto(userId) {
  let url = `${BASE_URL}/user/delete-avatar`;
  if (userId) {
    url = `${BASE_URL}/admin/user/delete-avatar/${userId}`;
  }
  return remove(url);
}

export function deleteUser(userId) {
  let url = `${BASE_URL}/admin/user/delete-user/${userId}`;
  return remove(url);
}

export function update(params, userId) {
  let url = `${BASE_URL}/user`;
  if (userId) {
    url = `${BASE_URL}/admin/user/edit/${userId}`;
  }
  return put(url, params);
}

export function create(params) {
  return post(`${BASE_URL}/admin/user`, params);
}

export function getUsers(params) {
  return get(`${BASE_URL}/admin/users`, params);
}

export function exportUsers(params) {
  return downloadGet(`${BASE_URL}/admin/users/export`, params, 'users.csv');
}

export function getAllUsers(params) {
  return get(`${BASE_URL}/admin/users/all`, params);
}

export function getUsersByUsersId(params) {
  return get(`${BASE_URL}/admin/users/chat?usersID=${params}`, params);
}
