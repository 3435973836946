import React from 'react';

export default function ProgressBar({ file, progress, resetFile }) {
  return (
    <div className='progress-bar-file'>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className={`progress-bar ${progress ? '' : 'progress-bar-disabled'}`}>
          <div className={`${progress ? 'progress' : ''}`} style={{ width: progress + '%' }} />
        </div>
      </div>
    </div>
  );
}
