import React, { useEffect, useRef, useState } from 'react';
import './styles.css';
import { Alert, AlertTitle, Checkbox, Chip, Grid } from '@mui/material';
import { useLanguageContext } from '../../contexts/languageContext';
import { createTimecycle, deleteTimecycle, getTimecycles } from '../../services/timecyclesService';
import uuid from 'react-uuid';
import { Field, Form } from 'react-final-form';
import TextField from '../Form/TextField';
import Error from '../Form/Error';
import DatePicker from '../Form/DatePicker';
import moment from 'moment';
import { CheckCircleOutline } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import accountImage from '../../images/default_account.png';
import { MultiSelectFilter } from '../ReactTable';
import { getJobPositions } from '../../services/masterDataService';
import { getAllUsers } from '../../services/userService';
import ReactTableStatic from '../ReactTableStatic';
import { FORM_ERROR } from 'final-form';

function Timecycles() {
  const { gettext } = useLanguageContext();
  const [timecycles, setTimecycles] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [timecycleUpdated, setTimecycleUpdated] = useState(false);
  const [jobPositions, setJobPositions] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState({});
  const [selectedIds, setSelectedIds] = useState({});
  const tableRef = useRef();

  useEffect(() => {
    getAllUsers().then((result) => {
      setUsers(result.data);
    });
  }, []);
  useEffect(() => {
    getJobPositions()
      .then((result) => {
        let jobs = result.data.map((job) => {
          return {
            id: job,
            name: gettext('APP.USERS.JOBS.' + job)
          };
        });
        jobs.unshift({
          id: '',
          name: ''
        });
        setJobPositions(jobs);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getTimecycles().then((result) => {
      let defaultTC;
      result.data = result.data.filter((timecycle) => {
        timecycle.key = uuid();
        if (typeof timecycle.userIds === 'undefined') timecycle.userIds = [];
        if (typeof timecycle.selectAll === 'undefined') timecycle.selectAll = false;
        timecycle.sendEmailsDate = moment.utc(timecycle.sendEmailsDate).local();
        timecycle.deadLineUsersChoiceDate = moment.utc(timecycle.deadLineUsersChoiceDate).local();
        timecycle.lockedEndDate = moment.utc(timecycle.lockedEndDate).local();
        timecycle.locked = moment() < timecycle.lockedEndDate;

        timecycle.timeslots.map((timeslot) => {
          timeslot.key = uuid();
          timeslot.dateAndTime = moment.utc(timeslot.dateAndTime).local();
        });
        if (timecycle.default) {
          setExpanded(timecycle.key);
          defaultTC = timecycle;
        } else {
          return timecycle;
        }
      });
      result.data.unshift(defaultTC);

      setTimecycles(result.data);
    });
  }, []);

  useEffect(() => {
    if (timecycleUpdated) document.getElementById(timecycleUpdated).scrollIntoView();
  }, [timecycleUpdated]);

  const setSelectAllHandler = (data, timecycleId) => {
    timecycles.map((tc) => {
      if (tc.id === timecycleId) {
        tc.selectAll = !tc.selectAll;
        let ids = [];
        if (tc.selectAll) {
          data.rows.map((user) => {
            ids.push(user.original.id);
          });
        }
        tc.userIds = [...ids];
      }
    });
    setTimecycles([...timecycles]);
  };

  const setSelectAllCheckboxHandler = (timecycleId, id) => {
    timecycles.map((tc) => {
      if (tc.id === timecycleId) {
        if (tc.userIds.indexOf(id) >= 0) {
          tc.userIds.splice(tc.userIds.indexOf(id), 1);
        } else {
          tc.userIds.push(id);
        }
      }
    });
    setTimecycles([...timecycles]);
  };

  const updateTimecycle = (timecycle) => {
    let tcycles = timecycles.map((tc) => {
      if (tc.key === timecycle.key) {
        tc = timecycle;
      }
      return tc;
    });
    setTimecycles(tcycles);
  };

  const addTimeslot = (timecycle) => {
    let newTimeslot = {
      key: uuid(),
      dateAndTime: ''
    };

    let tcycles = timecycles.map((tc) => {
      if (tc.key === timecycle.key) {
        timecycle.timeslots.push(JSON.parse(JSON.stringify(newTimeslot)));
      }
      return tc;
    });
    setTimecycles(tcycles);
  };

  const deleteTimeslot = (timecycle, timeslot) => {
    if (timecycle.timeslots.length === 1) return;
    let tcycles = timecycles.map((tc) => {
      if (tc.key === timecycle.key) {
        let timeslots = timecycle.timeslots.filter((ts) => {
          if (ts.key !== timeslot.key) {
            return ts;
          }
        });
        timecycle.timeslots = timeslots;
      }
      return tc;
    });

    setTimecycles(tcycles);
  };

  const addTimecycle = () => {
    let newTimeCycle = {
      key: uuid(),
      name: 'New time cycle',
      userIds: [],
      selectAll: false,
      timeslots: [
        {
          key: uuid(),
          dateAndTime: ''
        },
        {
          key: uuid(),
          dateAndTime: ''
        },
        {
          key: uuid(),
          dateAndTime: ''
        },
        {
          key: uuid(),
          dateAndTime: ''
        },
        {
          key: uuid(),
          dateAndTime: ''
        },
        {
          key: uuid(),
          dateAndTime: ''
        }
      ],
      sendEmailsDate: '',
      deadLineUsersChoiceDate: '',
      lockedEndDate: ''
    };
    setTimecycles([...timecycles, newTimeCycle]);
    setExpanded(newTimeCycle.key);
  };

  const deleteTimecycleHandler = (timecycle) => {
    if (timecycle.default) return false;
    if (!timecycle.id) {
      let tcycles = timecycles.filter((tc) => {
        if (tc.key !== timecycle.key) {
          return tc;
        }
      });
      setTimecycles(tcycles);
    } else {
      deleteTimecycle(timecycle.id).then((result) => {
        let tcycles = timecycles.filter((tc) => {
          if (tc.key !== timecycle.key) {
            return tc;
          }
        });
        setTimecycles(tcycles);
      });
    }
  };

  const saveTimecycle = (params) => {
    let filteredTimecycle = timecycles.filter((tc) => {
      if (tc.key === params.key) {
        return tc;
      }
    });

    setTimecycleUpdated(false);

    let timecycleToSubmit = { ...filteredTimecycle[0] };

    let error = null;

    if (timecycleToSubmit.sendEmailsDate < moment()) error = 'SEND_EMAILS_IN_PAST';
    if (timecycleToSubmit.deadLineUsersChoiceDate < timecycleToSubmit.sendEmailsDate) error = 'USERS_DEAD_LINE_IN_PAST';
    if (timecycleToSubmit.lockedEndDate < timecycleToSubmit.deadLineUsersChoiceDate) error = 'LOCKED_DATE_IN_PAST';

    timecycleToSubmit.timeslots = timecycleToSubmit.timeslots.filter((timeslot) => {
      if (timeslot.dateAndTime) {
        if (timeslot.dateAndTime <= timecycleToSubmit.deadLineUsersChoiceDate) error = 'TIME_IN_PAST';
        return timeslot;
      }
    });
    if (!timecycleToSubmit.default) {
      if (timecycleToSubmit.userIds.length < 2) error = 'AT_LEAST_USERS';
    }

    if (error) return { [FORM_ERROR]: error };

    createTimecycle(timecycleToSubmit).then((res) => {
      getTimecycles()
        .then((result) => {
          let defaultTC;
          result.data = result.data.filter((timecycle) => {
            timecycle.changed = false;
            if (timecycleToSubmit.name === timecycle.name) setTimecycleUpdated(timecycle.id);
            timecycle.key = uuid();
            timecycle.sendEmailsDate = moment.utc(timecycle.sendEmailsDate).local();
            timecycle.deadLineUsersChoiceDate = moment.utc(timecycle.deadLineUsersChoiceDate).local();
            timecycle.lockedEndDate = moment.utc(timecycle.lockedEndDate).local();
            timecycle.locked = moment() < timecycle.lockedEndDate;
            timecycle.timeslots.map((timeslot) => {
              timeslot.key = uuid();
              timeslot.dateAndTime = moment.utc(timeslot.dateAndTime).local();
            });
            if (timecycle.default) {
              setExpanded(timecycle.key);
              defaultTC = timecycle;
            } else {
              return timecycle;
            }
          });
          result.data.unshift(defaultTC);

          setTimecycles([...result.data]);
        })
        .catch((err) => {
          return Promise.resolve({ [FORM_ERROR]: err && err.data && err.data.code ? err.data.code : 'GENERAL' });
        });
    });
  };

  const columns = [
    {
      header: (data) => {
        return (
          <Checkbox
            id={'excludeAll'}
            checked={data.customData.selectAll}
            disabled={data.customData.locked}
            onClick={() => {
              setSelectAllHandler(data, data.customData.id);
            }}
            label={gettext('Избери всички')}
          />
        );
      },
      accessor: 'id',
      Cell: (data) => {
        return (
          <Checkbox
            disabled={data.customData.locked}
            checked={data.customData.userIds && data.customData.userIds.indexOf(data.row.original.id) >= 0}
            onChange={(e) => setSelectAllCheckboxHandler(data.customData.id, data.row.original.id)}
          />
        );
      },
      width: 20,
      disableSortBy: true,
      Filter: MultiSelectFilter(
        [
          { id: true, name: 'yes' },
          { id: false, name: 'no' }
        ],
        false,
        ''
      )
    },
    {
      header: gettext('APP.ADMINS.USERS.FULL_NAME'),
      accessor: 'fullName',
      Cell: (data) => {
        const name = data.row.original.fullName;
        const image = data.row.original.imageUrl;
        return (
          <div className={'name'}>
            <div className={'profile-picture'}>
              <Link to={'/profile/' + data.row.original.id}>{image ? <img src={image} alt='Profile' /> : <img src={accountImage} alt='Profile' />}</Link>
            </div>
            <div>
              <Link to={'/profile/' + data.row.original.id}>
                <div>{name}</div>
              </Link>
            </div>
          </div>
        );
      },
      width: '35%'
    },
    {
      header: gettext('APP.ADMINS.USERS.CITY'),
      accessor: 'city',
      width: '10%'
    },
    {
      header: gettext('APP.ADMINS.USERS.COMPANY'),
      accessor: 'companyName',
      width: '15%'
    },
    {
      header: gettext('APP.ADMINS.USERS.JOB_POSITION'),
      accessor: 'jobPosition',
      Cell: (data) => {
        const value = data.row.original.jobPosition;
        return <>{value ? gettext('APP.USERS.JOBS.' + value) : ''}</>;
      },
      Filter: MultiSelectFilter(jobPositions, false, gettext('APP.ADMINS.USERS.JOB_POSITION')),
      width: '40%'
    }
  ];
  const actions = [];

  return (
    <>
      <Grid container className='user-page-container profile-form'>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <h1 className={'profile-title'}>{gettext('APP.ADMIN.TIMECYCLES.TITLE')}</h1>
        </Grid>
        <Grid container>
          {timecycles &&
            timecycles.map((timecycle) => {
              return (
                <Grid key={timecycle.key} sx={{ mt: 1, mb: 2 }} item xs={12} sm={12} md={121} lg={12} xl={12} className={'timecycle'}>
                  <Grid
                    container
                    onClick={() => {
                      setExpanded(expanded !== timecycle.key ? timecycle.key : false);
                    }}
                    className={'pointer'}
                  >
                    <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                      <h2 id={timecycle.id || timecycle.key}>
                        {timecycle.name} {timecycle.default && <Chip className={'chip'} label='Default' />}
                      </h2>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1} className={'text-right'}>
                      <span className={expanded === timecycle.key ? 'arrow' : 'arrow active'}>
                        <span></span>
                        <span></span>
                      </span>
                    </Grid>
                  </Grid>

                  {timecycle.id && timecycle.id == timecycleUpdated && (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Alert severity='success' icon={<CheckCircleOutline sx={{ fontSize: 50 }} />}>
                        <AlertTitle>{gettext('APP.ADMIN.TIMECYCLE_WARNINGS.TIMECYCLE_UPDATE_TITLE')}</AlertTitle>
                        {gettext('APP.ADMIN.TIMECYCLE_WARNINGS.TIMECYCLE_UPDATE_TEXT')}
                      </Alert>
                    </Grid>
                  )}
                  {expanded === timecycle.key && (
                    <Grid container className={'timecycle-content'}>
                      <Grid item sx={{ pt: 3 }} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form
                          onSubmit={saveTimecycle}
                          initialValues={timecycle}
                          render={({ handleSubmit, submitError, submitting }) => {
                            return (
                              <form onSubmit={handleSubmit}>
                                <Grid container>
                                  <Grid item xs={10} sm={10} md={10} lg={8} xl={8}>
                                    {!timecycle.default && (
                                      <Grid item xs={12} sm={12} md={8} lg={7} xl={7}>
                                        <Field
                                          component={TextField}
                                          disabled={timecycle.locked}
                                          name='name'
                                          id='name'
                                          label={gettext('APP.ADMINS.TIMESLOT.NAME_LABEL')}
                                          placeholder={gettext('APP.ADMINS.TIMESLOT.NAME_PLACEHOLDER')}
                                          type='text'
                                          noasterisk='true'
                                          onChange={(name) => {
                                            timecycle.name = name;
                                            setTimecycles(timecycles);
                                          }}
                                        />
                                      </Grid>
                                    )}
                                    <Grid item xs={12} sm={12} md={8} lg={7} xl={7}>
                                      <Field
                                        component={DatePicker}
                                        className='input-date'
                                        disabled={timecycle.locked}
                                        dateFormat='dd.MM.yyyy HH:mm'
                                        showTimeSelect
                                        timeIntervals={30}
                                        defaultDate={timecycle.sendEmailsDate}
                                        name='sendEmailsDate'
                                        id='sendEmailsDate'
                                        label={gettext('APP.ADMINS.TIMESLOT.SEND_EMAIL_DATE_LABEL')}
                                        placeholder={gettext('APP.ADMINS.TIMESLOT.SEND_EMAIL_DATE_PLACEHOLDER')}
                                        type='text'
                                        noasterisk='true'
                                        minDate={new Date()}
                                        onChange={(date) => {
                                          timecycle.sendEmailsDate = moment(date).utc().format();
                                          updateTimecycle(timecycle);
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8} lg={7} xl={7}>
                                      <Field
                                        component={DatePicker}
                                        className='input-date'
                                        disabled={timecycle.locked}
                                        dateFormat='dd.MM.yyyy HH:mm'
                                        showTimeSelect
                                        timeIntervals={30}
                                        defaultDate={timecycle.deadLineUsersChoiceDate}
                                        name='deadLineUsersChoiceDate'
                                        id='deadLineUsersChoiceDate'
                                        label={gettext('APP.ADMINS.TIMESLOT.USERS_DEADLINE_DATE_LABEL')}
                                        placeholder={gettext('APP.ADMINS.TIMESLOT.USERS_DEADLINE_DATE_PLACEHOLDER')}
                                        type='text'
                                        noasterisk='true'
                                        minDate={Date.parse(timecycle.sendEmailsDate)}
                                        onChange={(date) => {
                                          timecycle.deadLineUsersChoiceDate = moment(date).utc().format();
                                          updateTimecycle(timecycle);
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8} lg={7} xl={7}>
                                      <Field
                                        component={DatePicker}
                                        className='input-date'
                                        disabled={timecycle.locked}
                                        dateFormat='dd.MM.yyyy HH:mm'
                                        showTimeSelect
                                        timeIntervals={30}
                                        defaultDate={timecycle.lockedEndDate}
                                        name='lockedEndDate'
                                        id='lockedEndDate'
                                        label={gettext('APP.ADMINS.TIMESLOT.TIMECYCLE_LOCKED_DATE_LABEL')}
                                        placeholder={gettext('APP.ADMINS.TIMESLOT.TIMECYCLE_LOCKED_DATE_PLACEHOLDER')}
                                        type='text'
                                        noasterisk='true'
                                        minDate={Date.parse(timecycle.deadLineUsersChoiceDate)}
                                        onChange={(date) => {
                                          timecycle.lockedEndDate = moment(date).utc().format();
                                          updateTimecycle(timecycle);
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid item sx={{ mt: 2 }} xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <hr />
                                  </Grid>

                                  <Grid item sx={{ mt: 2 }} xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid container spacing={2}>
                                      {timecycle.timeslots.map((timeslot) => {
                                        return (
                                          <Grid key={timeslot.key} item sx={{ pl: 0.5 }} xs={12} sm={10} md={4} lg={3} xl={4}>
                                            <Field
                                              component={DatePicker}
                                              className='input-date'
                                              timeIntervals={30}
                                              dateFormat='dd.MM.yyyy HH:mm'
                                              disabled={timecycle.locked}
                                              defaultDate={timeslot.dateAndTime}
                                              name={`${timeslot}.dateAndTime`}
                                              label={gettext('APP.ADMINS.TIMESLOT.DAY_LABEL')}
                                              placeholder={gettext('APP.ADMINS.TIMESLOT.DAY_PLACEHOLDER')}
                                              type='text'
                                              noasterisk='true'
                                              showTimeSelect
                                              minDate={Date.parse(timecycle.sendEmailsDate)}
                                              btnClick={
                                                timecycle.timeslots.length > 1 && !timecycle.locked
                                                  ? () => {
                                                      deleteTimeslot(timecycle, timeslot);
                                                    }
                                                  : ''
                                              }
                                              onChange={(date) => {
                                                timeslot.dateAndTime = moment(date).utc().format();
                                                updateTimecycle(timecycle);
                                              }}
                                            />
                                          </Grid>
                                        );
                                      })}
                                      <Grid item sx={{ mt: 2 }} xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <button
                                          type='button'
                                          disabled={timecycle.locked}
                                          className={'cancel-btn profile-btns'}
                                          onClick={() => {
                                            addTimeslot(timecycle);
                                          }}
                                        >
                                          {gettext('APP.ADMINS.TIMECYCLES.ADD')}
                                        </button>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item sx={{ mt: 2 }} xs={12} sm={12} md={12} lg={12} xl={12}>
                                    {!timecycle.default && (
                                      <Grid container className={'users-table-wrapper'} justifyContent={'center'}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                          {users.length && (
                                            <ReactTableStatic
                                              defaultPageSize={10}
                                              forceColumnRefresh
                                              columns={columns}
                                              data={users || []}
                                              actions={actions}
                                              dependencies={[jobPositions, selectAll, timecycle]}
                                              customData={timecycle}
                                            />
                                          )}
                                        </Grid>
                                      </Grid>
                                    )}
                                  </Grid>

                                  <Grid item sx={{ mt: 2 }} xs={8} sm={8} md={8} lg={8} xl={8}>
                                    <Grid item sx={{ mt: 2 }} xs={8} sm={8} md={8} lg={8} xl={8} className={'form-btns-container'}>
                                      <button type='submit' disabled={timecycle.locked} className={'save-btn profile-btns'}>
                                        {gettext('APP.USERS.COMMON.BUTTON_SAVE')}
                                      </button>
                                      {!timecycle.default && (
                                        <button
                                          type='button'
                                          disabled={timecycle.locked}
                                          className={'cancel-btn profile-btns'}
                                          onClick={() => deleteTimecycleHandler(timecycle)}
                                        >
                                          {gettext('APP.USERS.COMMON.BUTTON_DELETE')}
                                        </button>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  {submitError && <Error text={gettext('APP.ADMIN.TIMECYCLES_ERROR.' + submitError)} />}
                                </Grid>
                              </form>
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              );
            })}

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <button type='button' className={'upload-btn profile-btns'} onClick={addTimecycle}>
              {gettext('APP.ADMINS.TIMECYCLES.ADD')}
            </button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Timecycles;
