import React from 'react';
import { LanguageProvider } from './languageContext';
import { UserProvider } from './userContext';
import { ConversationsProvider } from './ConversationsContext';
import { ParticipantsProvider } from './ParticipantsContext';
import { MessagesProvider } from './MessagesContext';
import { UnreadMessagesProvider } from './UnreadMessagesContext';
import { NotificationsProvider } from './NotificationsContext';
import { TypingDataProvider } from './TypingDataContext';
import { AttachmentsProvider } from './AttachmentsContext';

export default function MasterProvider({ children }) {
  return (
    <LanguageProvider>
      <UserProvider>
        <ConversationsProvider>
          <ParticipantsProvider>
            <MessagesProvider>
              <NotificationsProvider>
                <UnreadMessagesProvider>
                  <TypingDataProvider>
                    <AttachmentsProvider>{children}</AttachmentsProvider>
                  </TypingDataProvider>
                </UnreadMessagesProvider>
              </NotificationsProvider>
            </MessagesProvider>
          </ParticipantsProvider>
        </ConversationsProvider>
      </UserProvider>
    </LanguageProvider>
  );
}
