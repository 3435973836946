import React, { useRef, useState } from 'react';
import './styles.css';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { useLanguageContext } from '../../contexts/languageContext';
import ReactTablePagination from '../ReactTablePagination';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import { attachReview, exportReviews, getReviews } from '../../services/reviewsService';
import moment from 'moment';
import Popup from '../Popup';
import { Field, Form } from 'react-final-form';
import TextArea from '../Form/TextArea';
import { required } from '../../utils/form';
import { FORM_ERROR } from 'final-form';
import Error from '../Form/Error';

function Reviews() {
  const { gettext } = useLanguageContext();
  const tableRef = useRef();
  const [attachPopup, setAttachPopup] = useState(undefined);
  const [selected, setSelected] = useState(undefined);
  const attachForm = useRef();
  const [downloading, setDownloading] = useState(false);

  const columns = [
    {
      header: gettext('APP.ADMINS.REVIEWS.REVIEWER_NAME'),
      accessor: 'reviewerName',
      width: '15%'
    },
    {
      header: gettext('APP.ADMINS.REVIEWS.REVIEWED_NAME'),
      accessor: 'reviewedName',
      width: '15%'
    },
    {
      header: gettext('APP.ADMINS.REVIEWS.RELEVANCE_SCORE'),
      accessor: 'relevanceScore',
      width: '5%'
    },
    {
      header: gettext('APP.ADMINS.REVIEWS.FEEDBACK'),
      accessor: 'feedback',
      width: '55%'
    },
    {
      header: gettext('APP.ADMINS.REVIEWS.HOOKUP_DATE'),
      accessor: 'hookupDate',
      Filter: () => {},
      Cell: (data) => {
        return <div>{moment.utc(data.row.original.hookupDate).local().format('DD.MM.YYYY').toString()}</div>;
      },
      width: '10%'
    }
  ];
  const actions = [
    {
      iconcls: <PersonAddAltOutlinedIcon />,
      title: gettext('APP.ADMIN.REVIEWS.ATTACH'),
      onClick: (dummyParam, { row }) => {
        setSelected(row.original);
        setAttachPopup(true);
      }
    }
  ];
  const renderTable = (
    <ReactTablePagination
      defaultPageSize={10}
      ref={tableRef}
      columns={columns}
      api={getReviews}
      actions={actions}
      dependencies={[]}
      defaultSorted={[
        {
          id: 'createdOn',
          desc: true
        }
      ]}
      customButtons={
        <div style={{ display: 'flex' }}>
          <div style={{ height: '26px' }}>
            <Tooltip title={gettext('APP.COMMON.COMMON.EXPORT_CSV')}>
              <IconButton
                disabled={downloading}
                onClick={() => {
                  setDownloading(true);
                  return exportReviews(tableRef.current.getSearchParams()).then(() => setDownloading(false));
                }}
              >
                {!downloading && <FileDownloadOutlinedIcon />}
                {downloading && <HourglassEmptyOutlinedIcon />}
              </IconButton>
            </Tooltip>
          </div>
        </div>
      }
    />
  );
  const onSubmit = (params) => {
    attachReview(params)
      .then((result) => {
        setSelected(false);
        setAttachPopup(false);
      })
      .catch((err) => {
        return Promise.resolve({ [FORM_ERROR]: err && err.response && err.response.data && err.response.data.code ? err.response.data.code : 'GENERAL' });
      });
  };

  return (
    <>
      {attachPopup && (
        <Popup open={attachPopup} onClose={() => setAttachPopup(false)} title={gettext('APP.ADMIN.REVIEWS.ATTACH_TO_PROFILE_TITLE')}>
          <Form
            onSubmit={onSubmit}
            initialValues={selected}
            render={({ handleSubmit, submitError, submitting }) => {
              return (
                <form onSubmit={handleSubmit} ref={attachForm}>
                  <Grid className={'form-inner-container'} spacing={1} container>
                    <Grid item xs={12} sm={12} md={11} lg={8} xl={7}>
                      <Field
                        component={TextArea}
                        name='feedback'
                        id='feedback'
                        label={gettext('APP.ADMINS.REVIEWS.FEEDBACK_LABEL')}
                        placeholder={gettext('APP.ADMINS.REVIEWS.FEEDBACK_PLACEHOLDER')}
                        type='text'
                        validate={required}
                        required
                      />
                    </Grid>
                  </Grid>
                  <br />

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {submitError && <Error text={gettext('APP.ADMIN.REVIEWS_ERROR.' + submitError)} />}
                  </Grid>

                  <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                    <Grid className={'form-btns-container'}>
                      <button
                        type='button'
                        className={'cancel-btn profile-btns'}
                        onClick={() => {
                          setAttachPopup(false);
                        }}
                      >
                        {gettext('APP.ADMIN.COMMON.BUTTON_CANCEL')}
                      </button>
                      <button className={'save-btn profile-btns'} type={'submit'}>
                        {gettext('APP.ADMIN.COMMON.BUTTON_SAVE')}
                      </button>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          />
        </Popup>
      )}
      <Grid container className='user-page-container'>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <h1 className={'profile-title'}>{gettext('APP.ADMIN.REVIEWS.TITLE')}</h1>
        </Grid>
        <Grid container className={'users-table-wrapper'} justifyContent={'center'}>
          <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
            {renderTable}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Reviews;
