import React, { useContext, useState } from 'react';
import { getLang, setLang } from '../utils/language';

const translations = {
  en: {}
};

export const LanguageContext = React.createContext();

export const useLanguageContext = () => useContext(LanguageContext);

export function LanguageProvider(props) {
  const [language, setLng] = useState(getLang());

  const setLanguage = (language) => {
    setLang(language);
    setLng(language);
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage, gettext, ngettext, getPageLang, gettextPlaceholder }}>{props.children}</LanguageContext.Provider>
  );

  function getTranslations() {
    if (translations[language] && Object.keys(translations[language]).length > 0) {
      return translations[language];
    }
    var newTranslations = require(`../locales/${language}/translations.json`);
    translations[language] = { ...newTranslations };

    return translations[language];
  }

  function getPluralForm(n) {
    const { plural } = getTranslations();

    // return 0 if n is not integer
    if (isNaN(parseInt(n, 10))) {
      return 0;
    }

    // if pluralForm is function, use it to get plural form index
    if (typeof plural === 'function') {
      return plural(n);
    }

    // if pluralForm is string and contains only "n", "0-9", " ", "!=?:%+-/*><&|()"
    // characters, then we can "eval" it to calculate plural form
    if (typeof plural === 'string' && !plural.match(/[^n0-9 !=?:%+-/*><&|()]/i)) {
      /* eslint-disable no-new-func */
      const calcPlural = Function('n', `return ${plural}`);
      /* eslint-enable no-new-func */
      return +calcPlural(n);
    }

    return 0;
  }

  function gettext(message) {
    const messages = getTranslations();

    if (typeof message !== 'string' || message.substring(0, 4) !== 'APP.') {
      return Object.prototype.hasOwnProperty.call(messages, message) ? (Array.isArray(messages[message]) ? messages[message][0] : messages[message]) : message;
    }

    var trans = messages;
    var keys = message.split('.');
    keys.forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(trans, key)) {
        trans = Array.isArray(trans[key]) ? trans[key][0] : trans[key];
      } else {
        trans = message;
      }
    });
    return trans;
  }

  function ngettext(singular, plural, n) {
    const messages = getTranslations();
    const pluralIndex = getPluralForm(n);
    const defaultValue = n > 1 ? plural : singular;

    return Object.prototype.hasOwnProperty.call(messages, singular) &&
      Array.isArray(messages[singular]) &&
      messages[singular].length > pluralIndex &&
      pluralIndex >= 0
      ? messages[singular][pluralIndex]
      : defaultValue;
  }

  function getPageLang() {
    return getLang();
  }

  function gettextPlaceholder(str, arr) {
    return gettext(str).replace(/{(\d*)}/g, (_, i) => (typeof arr[i] !== 'undefined' ? arr[i] : ''));
  }
}
