import React, { useContext, useState } from 'react';

const ParticipantsContext = React.createContext();

export const useParticipantsContext = () => useContext(ParticipantsContext);

export function ParticipantsProvider({ children }) {
  const [participants, setParticipantsToState] = useState([]);
  const [askToRegister, setAskToRegister] = useState(true);

  return (
    <ParticipantsContext.Provider
      value={{
        participants,
        setParticipants: (participants) => {
          setParticipantsToState(participants);
        }
      }}
    >
      {children}
    </ParticipantsContext.Provider>
  );
}
