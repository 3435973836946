import React, { useEffect, useRef, useState } from 'react';
import { getProperty } from '../../utils/common';

export default function ActionIcon({ cell, action }) {
  const [loading, setLoading] = useState(false);
  const componentIsMounted = useRef(true);
  const disabled = getProperty(action.disabled, cell && cell.value) || loading;

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  return (
    <div
      className={`icon grid-icon ${
        loading ? 'icon-loader' : typeof getProperty(action.iconcls, cell && cell.value) === 'string' ? getProperty(action.iconcls, cell && cell.value) : ''
      }`}
      title={getProperty(action.title, cell && cell.value)}
      disabled={disabled}
      style={{ ...{ display: 'table-cell' }, ...(!disabled && { cursor: 'pointer' }) }}
      onClick={(e) => {
        if (!disabled) {
          e.preventDefault();
          e.stopPropagation();
          if (action.onClick) {
            if (typeof action.onClick !== 'function') setLoading(true);
            Promise.resolve(action.onClick(cell && cell.value, cell))
              .then(() => componentIsMounted.current && setLoading(false))
              .catch(() => componentIsMounted.current && setLoading(false));
          }
        }
      }}
    >
      {typeof getProperty(action.iconcls, cell && cell.value) === 'object' && getProperty(action.iconcls, cell && cell.value)}
    </div>
  );
}
