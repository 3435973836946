import { get, put, downloadGet } from '../utils/api';
import { BASE_URL } from '../utils/consts';
import moment from 'moment';

export function getReviews(params) {
  return get(`${BASE_URL}/reviews`, params);
}

export function attachReview(params) {
  return put(`${BASE_URL}/admin/user/feedback/${params.id}?feedback=${params.feedback}`, params);
}

export function exportReviews(params) {
  const formattedDate = moment(new Date()).format("YYYY-MM-DD-HHmm");
  const csvFileName = `Reviews-Report-${formattedDate}.csv`;
  return downloadGet(`${BASE_URL}/reviews/export`, params, csvFileName);
}