import React, { useContext, useState } from 'react';

const MessagesContext = React.createContext();

export const useMessagesContext = () => useContext(MessagesContext);

export function MessagesProvider({ children }) {
  const [messages, setMessagesToState] = useState({});
  const [askToRegister, setAskToRegister] = useState(true);

  return (
    <MessagesContext.Provider
      value={{
        messages,
        setMessages: (messages) => {
          setMessagesToState(messages);
        }
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
}
