import React, { useContext, useState } from 'react';

const ConversationsContext = React.createContext();

export const useConversationsContext = () => useContext(ConversationsContext);

export function ConversationsProvider({ children }) {
  const [loadingStatus, setLoadingStatusToState] = useState(true);
  const [lastReadIndex, setLastReadIndexToState] = useState(-1);
  const [conversations, setConversationsToState] = useState([]);
  const [askToRegister, setAskToRegister] = useState(true);

  return (
    <ConversationsContext.Provider
      value={{
        conversations,
        setConversations: (conversations) => {
          setConversationsToState(conversations);
        },
        loadingStatus,
        setLoadingStatus: (loadingStatus) => {
          setLoadingStatusToState(loadingStatus);
        },
        lastReadIndex,
        setLastReadIndex: (readIndex) => {
          setLastReadIndexToState(readIndex);
        }
      }}
    >
      {children}
    </ConversationsContext.Provider>
  );
}
