import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useLanguageContext } from '../../contexts/languageContext';
import { getItem, setItem } from '../../utils/storage';

import { useFilters, usePagination, useSortBy, useTable } from 'react-table';
import Select, { components, createFilter } from 'react-select';
import ActionIcon from './actionIcon';

const ReactTableFilters = forwardRef(function (
  { customButtons, customMessage, disableFilters, openAddPopup, customAddAction, totalRows, selectRow, forceColumnRefresh = false, ...others },
  ref
) {
  const rootEl = useRef();
  const [isSuccess, setIsSuccess] = useState(true);
  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase()) : true;
        });
      }
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter
    }),
    []
  );

  const colTypes = (col) => {
    switch (col.type) {
      case 'date':
        return {};
      case 'dateTime':
        return {};
      case 'dateRange':
        return {};
      case 'sum':
        return {};
      default:
        return {};
    }
  };

  function getProperty(prop, data) {
    return typeof prop === 'function' ? prop(data) : prop;
  }

  const processColumns = (cols, actions) => {
    const columns = cols.map((col) => ({ ...colTypes(col), ...col }));

    if (actions) {
      const actionColumn = {
        header: '',
        className: 'action-column',
        resizable: false,
        sortable: false,
        accessor: 'actions',
        width: 'auto',
        Filter: () => null
      };

      actionColumn.Cell = (cell) =>
        actions.reduce((acc, action, index) => {
          const allowed = action.onlyInMenu
            ? false
            : action.condition
            ? getProperty(action.condition, typeof cell.value === 'undefined' ? cell.row : cell.value)
            : true;
          return allowed ? [...acc, <ActionIcon key={index} cell={cell} action={action} />] : acc;
        }, []);

      return [...columns, actionColumn];
    } else {
      return columns;
    }
  };

  const columnsToShow = useMemo(
    () => processColumns(others.columns, others.actions),
    [others.columns && others.columns.length, others.actions && others.actions.length, ...others.dependencies]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    page,
    prepareRow,
    state: { pageIndex, pageSize, sortBy, filters }
  } = useTable(
    {
      columns: columnsToShow,
      data: isSuccess ? others.data : [],
      initialState: {
        pageIndex: 0,
        pageSize: others.defaultPageSize
      },
      defaultColumn,
      autoResetPage: false,
      manualSortBy: true,
      manualFilters: true,
      manualPagination: true,
      pageCount: isSuccess ? others.pages : null,
      filterTypes
    },
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (typeof others.onFetchData === 'function') {
      others.onFetchData({
        page: pageIndex,
        pageSize: pageSize,
        sorted: sortBy.length > 0 ? sortBy : others.defaultSorted,
        filtered: filters.length > 0 ? filters : []
      });
    }
  }, [pageIndex, pageSize, sortBy, filters]);

  const storageName = 'saved_grid_options';
  const savedAllOptions = JSON.parse(getItem(storageName));
  const savedOptions = savedAllOptions && others.title && savedAllOptions[others.title];
  //
  const { gettext } = useLanguageContext();
  const [showFilters, setShowFilters] = useState(savedOptions ? savedOptions.showFilters : false);
  const setShowFiltersState = (show) => {
    setShowFilters(show);
    if (others.title) {
      const newOptions = {
        ...savedAllOptions,
        [others.title]: { ...(savedAllOptions && savedAllOptions[others.title]), showFilters: show }
      };
      setItem(storageName, JSON.stringify(newOptions));
    }
  };

  return (
    <div className='react-table-filterable' ref={rootEl}>
      <div className='header-container-wrapper'>
        <table className='header-container'>
          <tbody>
            <tr>
              <td>
                {disableFilters || (
                  <div className='filters-btn' onClick={() => setShowFiltersState(!showFilters)}>
                    <span className={!showFilters ? 'arrow active' : 'arrow'}>
                      <span></span>
                      <span></span>
                    </span>
                    {!showFilters ? gettext('APP.COMMON.TABLE.SHOW_FILTERS') : gettext('APP.COMMON.TABLE.HIDE_FILTERS')}
                  </div>
                )}
              </td>
              {others.title && (
                <td className='table-title'>
                  <div>{others.title}</div>
                </td>
              )}
              <td></td>
              <td className='add-btn'>{customButtons && <div className='custom-buttons'>{customButtons}</div>}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <table {...getTableProps()}>
        <thead>
          {showFilters &&
            headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className={'no-border'}>
                {headerGroup.headers.map((column, i) => (
                  <td
                    {...column.getHeaderProps({
                      ...column.getSortByToggleProps(),
                      ...{
                        style: { minWidth: column.minWidth, width: column.width }
                      }
                    })}
                    onClick={() => {}}
                    title=''
                  >
                    {column.canFilter ? column.render('Filter') : null}
                  </td>
                ))}
              </tr>
            ))}
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <th
                  key={i}
                  {...column.getHeaderProps({
                    ...column.getSortByToggleProps(),
                    ...{
                      style: { minWidth: column.minWidth, width: column.width }
                    }
                  })}
                >
                  {column.isSorted && (
                    <span className={!column.isSortedDesc ? 'arrow active' : 'arrow'}>
                      <span></span>
                      <span></span>
                    </span>
                  )}
                  {column.render('header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className='pagination'>
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type='number'
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
});

// Define a default UI for filtering
export function DefaultColumnFilter({ column: { header, filterValue, preFilteredRows, setFilter, filter, ...others } }) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ''}
      placeholder={header}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
    />
  );
}

export const filterCellRenderer =
  (arr, prop) =>
  ({ value }) => {
    const obj = arr.find((o) => o.id === value);
    return obj ? <span title={obj[prop]}>{obj[prop]}</span> : '';
  };

export const SelectColumnFilter =
  (options, sendId = false) =>
  ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
    return (
      <select
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option />
        {options.map((option, i) => (
          <option key={option.key} value={option.key}>
            {option.name}
          </option>
        ))}
      </select>
    );
  };

const CustomOption = (props) => {
  const { data, children } = props;
  return (
    <components.Option {...props}>
      <span title={data.name}>{children}</span>
    </components.Option>
  );
};
export const TextDropdownFilter =
  (options, sendId = false) =>
  ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
    return (
      <select
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        value={filterValue}
      >
        <option />
        {Array.isArray(options)
          ? options.map(({ name, description, id, label }) => (
              <option key={id} value={sendId ? id : name || description}>
                {label || name || description}
              </option>
            ))
          : Object.keys(options).map((key) => (
              <option key={key} value={key}>
                {options[key]}
              </option>
            ))}
      </select>
    );
  };

export const MultiSelectFilter =
  (opts, isMultiselect = false, placeholder = 'Search') =>
  ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
    return (
      <div className='input-group'>
        <Select
          className={`multi-select-input searchable`}
          classNamePrefix='react-select'
          isMulti={isMultiselect}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          placeholder={placeholder}
          isSearchable={true}
          filterOption={createFilter({ ignoreAccents: false })}
          onChange={(e) => {
            setFilter(e.key || e.id || undefined);
          }}
          styles={{
            singleValue: ({ maxWidth, position, top, transform, ...otherStyles }) => ({ ...otherStyles })
          }}
          options={opts}
          components={{ Option: CustomOption }}
        />
        {/* The hidden input is needed for displaying the error messages in field validation
      https://github.com/JedWatson/react-select/issues/3140 */}
        <input
          tabIndex={-1}
          autoComplete='off'
          style={{ opacity: 0, height: 0, padding: 0 }}
          onChange={(e) => {
            console.log(e);
          }}
        />
      </div>
    );
  };

export default ReactTableFilters;
