import React, { useEffect, useRef, useState } from 'react';
import { useLanguageContext } from '../../../contexts/languageContext';

import 'react-datepicker/dist/react-datepicker.css';

import dateFnsFormat from 'date-fns/format';
import DatePicker from 'react-datepicker';
import { IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import moment from 'moment';

function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, { locale });
}

export default function DatePickr({
  input: { value, onFocus, onBlur },
  defaultDate,
  onChange,
  meta,
  required,
  placeholderText,
  timeIntervals,
  label,
  btnClick,
  showTimeSelect,
  dateFormat,
  dayPickerProps,
  disabledBefore,
  disabledDays,
  disabledAfter,
  className,
  minDate,
  ...others
}) {
  const format = dateFormat || 'dd/MM/yyyy';
  const [selectedDate, setSelectedDate] = useState(defaultDate ? moment(defaultDate).toDate() : null);

  const { gettext } = useLanguageContext();
  const style = (meta.error || meta.submitError) && meta.touched ? { color: 'red' } : {};

  const ref = useRef(null);
  useEffect(() => {
    ref.current && ref.current.setCustomValidity(meta.error && meta.touched ? gettext(meta.error) : '');
  });

  return (
    <div className='input-group' style={{ opacity: others.disabled ? 0.5 : 1, position: 'relative' }}>
      <label htmlFor='' style={style}>
        {label} {required && <span className='is-required'>*</span>}
      </label>
      <DatePicker
        className={{ container: className, overlay: '' }}
        placeholder={placeholderText}
        autoComplete='off'
        dateFormat={dateFormat}
        timeIntervals={timeIntervals || 15}
        showTimeSelect={showTimeSelect}
        selected={selectedDate}
        minDate={minDate ? minDate : false}
        disabled={others.disabled}
        onChange={(date) => {
          setSelectedDate(date);
          if (onChange) {
            onChange(date);
          }
        }}
      />
      {/* The hidden input is needed for displaying the error messages in field validation */}
      <input
        ref={ref}
        tabIndex={-1}
        autoComplete='off'
        style={{ position: 'absolute', width: 'auto', padding: 0, opacity: 0, height: 0 }}
        defaultValue={value}
      />
      {btnClick && !others.readOnly && (
        <IconButton sx={{ width: '10px', height: '10px', position: 'absolute', right: '10px', bottom: '20px' }} onClick={btnClick}>
          <CancelIcon />
        </IconButton>
      )}
    </div>
  );
}
