import React, { useEffect, useRef, useState } from 'react';
import './styles.css';
import { useLanguageContext } from '../../contexts/languageContext';
import { useParams } from 'react-router-dom';
import { getUsers } from '../../services/userService';
import { Alert, AlertTitle, Grid } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';
import { Field, Form } from 'react-final-form';
import MultiSelectField from '../Form/MultiSelect';
import DatePicker from '../Form/DatePicker';
import moment from 'moment';
import { getHookup, updateHookup } from '../../services/hookupsService';
import { FORM_ERROR } from 'final-form';
import Error from '../Form/Error';
import loader from '../../images/loader.gif';

function HookupForm() {
  const { gettext } = useLanguageContext();
  const [users, setUsers] = useState([]);
  const [hookup, setHookup] = useState({});
  const [initVals, setInitVals] = useState({});
  const [hookupChanged, setHookupChanged] = useState(false);
  const [date, setDate] = useState(false);
  const [loading, setLoading] = useState(true);
  const hookupForm = useRef();
  let { hookuupId } = useParams();

  const validate = (values) => undefined;

  const loadOptions = (value, cb) => {
    if (value) {
      const params = {
        params: {
          f_fullName: value,
          page_number: 0,
          page_size: 25,
          sorting_direction: 'ASC',
          sorting_parameter: 'fullName'
        }
      };
      getUsers(params).then(({ data }) => {
        let result = data.elements.map((user) => {
          return {
            id: user.id,
            name: user.fullName
          };
        });

        cb([
          {
            id: 0,
            name: ' '
          },
          ...new Map(result.map((m) => [m.id, m])).values()
        ]);
        setUsers([...users, ...data.elements]);
      });
    } else {
      cb([
        {
          id: 0,
          name: ' '
        }
      ]);
    }
  };

  useEffect(() => {
    getHookup({ id: hookuupId }).then((result) => {
      result.data.startDateTime = moment.utc(result.data.startDateTime).local();
      let pageHookup = result.data;
      setHookup(pageHookup);
      setInitVals({
        ...pageHookup,
        ...{
          first: pageHookup.firstParticipant
            ? {
                id: pageHookup.firstParticipant.id,
                name: pageHookup.firstParticipant.fullName
              }
            : '',
          second: pageHookup.secondParticipant
            ? {
                id: pageHookup.secondParticipant.id,
                name: pageHookup.secondParticipant.fullName
              }
            : ''
        }
      });
      setDate(pageHookup.startDateTime);
      setLoading(false);
    });
  }, []);

  const updateDate = (date) => {
    setHookup({ ...hookup, ...{ startDateTime: date } });
  };

  const onSubmit = (params) => {
    setHookupChanged(false);
    params.startDateTime = hookup.startDateTime;

    let firstParticipant = users.filter((user) => {
      if (params.first && user.id === params.first.id) return user;
    });
    let secondParticipant = users.filter((user) => {
      if (params.second && user.id === params.second.id) return user;
    });
    params.firstParticipant = firstParticipant[0] ? firstParticipant[0] : params.first ? initVals.firstParticipant : params.first;
    params.secondParticipant = secondParticipant[0] ? secondParticipant[0] : params.second ? initVals.secondParticipant : params.second;
    if (params.first && params.first.id === 0) params.firstParticipant = null;
    if (params.second && params.second.id === 0) params.secondParticipant = null;

    updateHookup(params)
      .then((result) => {
        setHookupChanged(true);
      })
      .catch((err) => {
        return Promise.resolve({ [FORM_ERROR]: err && err.data && err.data.code ? err.data.code : 'GENERAL' });
      });
  };

  return (
    <>
      {loading ? (
        <div width='100%' style={{ textAlign: 'center' }}>
          <img src={loader} alt='' style={{ width: 'auto' }} />
        </div>
      ) : (
        <Grid container className='hookup-page-container'>
          {hookupChanged && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Alert severity='success' icon={<CheckCircleOutline sx={{ fontSize: 50 }} />}>
                <AlertTitle>{gettext('APP.ADMIN.HOOKUP.HOOKUP_UPDATE_TITLE')}</AlertTitle>
                {gettext('APP.ADMIN.HOOKUP.HOOKUP_UPDATE_TEXT')}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <h1 className={'hookup-title'}>{gettext('APP.ADMIN.HOOKUP.TITLE')}</h1>
          </Grid>

          <Grid className={'hookup-form'} item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Form
              onSubmit={onSubmit}
              validate={validate}
              initialValues={initVals}
              render={({ handleSubmit, submitError, submitting }) => {
                return (
                  <form onSubmit={handleSubmit} ref={hookupForm}>
                    {initVals.startDateTime && (
                      <Grid className={'form-inner-container'} spacing={1} container>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Grid item xs={9} sm={9} md={6} lg={5} xl={5}>
                            <Field
                              component={MultiSelectField}
                              name='first'
                              id='firstParticipant'
                              label={gettext('APP.ADMINS.HOOKUPS.FIRST_PARTICIPANT_NAME')}
                              placeholder={gettext('APP.ADMINS.HOOKUPS.FIRST_PARTICIPANT_PLACEHOLDER')}
                              type='text'
                              noasterisk='true'
                              isMulti={false}
                              isSearchable={true}
                              loadOptions={loadOptions}
                            ></Field>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Grid item xs={9} sm={9} md={6} lg={5} xl={5}>
                            <Field
                              component={MultiSelectField}
                              name='second'
                              id='secondParticipant'
                              label={gettext('APP.ADMINS.HOOKUPS.SECOND_PARTICIPANT_NAME')}
                              placeholder={gettext('APP.ADMINS.HOOKUPS.SECOND_PARTICIPANT_PLACEHOLDER')}
                              type='text'
                              noasterisk='true'
                              isMulti={false}
                              isSearchable={true}
                              loadOptions={loadOptions}
                            ></Field>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Grid item xs={9} sm={9} md={6} lg={5} xl={5}>
                            <Field
                              component={DatePicker}
                              className='input-date'
                              dateFormat='dd.MM.yyyy HH:mm'
                              showTimeSelect
                              timeIntervals={30}
                              defaultDate={initVals.startDateTime}
                              name='startDateTime'
                              id='startDateTime'
                              label={gettext('APP.ADMINS.HOOKUPS.DATE')}
                              placeholder={gettext('APP.ADMINS.HOOKUPS.DATE_PLACEHOLDER')}
                              type='text'
                              noasterisk='true'
                              minDate={new Date()}
                              onChange={(date) => {
                                updateDate(moment(date).utc().format());
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    <br />

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      {submitError && <Error text={gettext('APP.ADMIN.HOOKUP_ERROR.' + submitError)} />}
                    </Grid>

                    <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                      <Grid className={'form-btns-container'}>
                        <button
                          type='button'
                          className={'cancel-btn profile-btns'}
                          onClick={() => {
                            window.location.href = '/hookups/';
                          }}
                        >
                          {gettext('APP.ADMIN.COMMON.BUTTON_CANCEL')}
                        </button>
                        <button type='button' className={'save-btn profile-btns'} type={'submit'}>
                          {gettext('APP.ADMIN.COMMON.BUTTON_SAVE')}
                        </button>
                      </Grid>
                    </Grid>
                  </form>
                );
              }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default HookupForm;
