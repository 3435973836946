import React, { useEffect, useMemo, useState } from 'react';

import { MAX_FILE_SIZE, UNEXPECTED_ERROR_MESSAGE } from '../../../../utils/consts';
import { getTypingMessage } from '../../../../utils/chat/helpers';
import { getSdkConversationObject } from '../../../../utils/chat/conversationsObjects';
import { Grid, IconButton, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { SendOutlined } from '@mui/icons-material';

const MessageInputField = (props) => {
  const [message, setMessage] = useState('');
  const [files, setFiles] = useState([]);
  // needed to clear input type=file
  const [filesInputKey, setFilesInputKey] = useState('input-key');
  const typingInfo = getTypingMessage(props.typingData);

  useEffect(() => {
    setMessage('');
    setFiles([]);
    setFilesInputKey(Date.now().toString());
  }, [props.convo]);

  useEffect(() => {
    if (!files.length) {
      setFilesInputKey(Date.now().toString());
    }
  }, [files]);

  const sdkConvo = useMemo(() => getSdkConversationObject(props.convo), [props.convo.sid]);

  const onFilesChange = (event) => {
    const { files: assets } = event.target;
    if (!assets?.length) {
      return;
    }

    const validFiles = Array.from(assets).filter(({ size }) => size < MAX_FILE_SIZE + 1);

    if (validFiles.length < assets.length) {
      // TODO: show error
    }

    setFiles([...files, ...validFiles]);
  };

  const onFileRemove = (file) => {
    const fileIdentityArray = file.split('_');
    const fileIdentity = fileIdentityArray.slice(0, fileIdentityArray.length - 1).join();
    const existentFiles = files.filter(({ name, size }) => name !== fileIdentity && size !== Number(fileIdentityArray[fileIdentityArray.length - 1]));

    setFiles(existentFiles);
  };

  const onMessageSend = async () => {
    if (message.length == 0) {
      return;
    }

    const { convo, client } = props;
    const currentDate = new Date();
    const sdkConvo = getSdkConversationObject(convo);
    const newMessageBuilder = sdkConvo.prepareMessage().setBody(message);

    const messageIndex = await newMessageBuilder.build().send();
    setMessage('');
    try {
      await sdkConvo.advanceLastReadMessageIndex(messageIndex ?? 0);
    } catch (e) {
      return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
    }
  };

  return (
    <Grid container style={{ padding: '10px' }} className={'message-textarea'}>
      <Grid item xs={12}>
        <TextField
          label='Message'
          value={message}
          autoComplete='off'
          onChange={(e) => {
            if (sdkConvo) sdkConvo.typing();
            setMessage(e.target.value);
          }}
          onKeyPress={async (e) => {
            if (e.key === 'Enter') {
              await onMessageSend();
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  onClick={async () => {
                    await onMessageSend();
                  }}
                  edge='end'
                >
                  <SendOutlined />
                </IconButton>
              </InputAdornment>
            )
          }}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default MessageInputField;
