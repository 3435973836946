import React, { useContext, useState } from 'react';

const UnreadMessagesContext = React.createContext();

export const useUnreadMessagesContext = () => useContext(UnreadMessagesContext);

export function UnreadMessagesProvider({ children }) {
  const [unreadMessages, setUnreadMessagesToState] = useState([]);
  const [askToRegister, setAskToRegister] = useState(true);

  return (
    <UnreadMessagesContext.Provider
      value={{
        unreadMessages,
        setUnreadMessages: (unreadMessages) => {
          setUnreadMessagesToState(unreadMessages);
        }
      }}
    >
      {children}
    </UnreadMessagesContext.Provider>
  );
}
