import React from 'react';
import loader from '../../images/loader.gif';
import { useLanguageContext } from '../../contexts/languageContext';

export default function LoadingBtn({ loading, disabled, text, iconCls, iconID, className, style, type, onClick, ...others }) {
  const { gettext } = useLanguageContext();

  return (
    <div data-testid='loadingButton' className={`text-center custom-button-holder ${className ? className : ''}`} style={style}>
      <button
        {...others}
        className='button button-primary custom-button'
        type={type || 'submit'}
        disabled={disabled || loading}
        onClick={onClick}
        style={{ opacity: disabled || loading ? 0.5 : 1 }}
      >
        {iconCls && <span id={iconID} className={iconCls} />}
        {text || gettext('Продължи')}
        {loading && <img src={loader} alt='' />}
      </button>
    </div>
  );
}
