import React, { useEffect, useRef, useState } from 'react';
import { getMessageStatus } from '../../api';
import MessageView from '../MessageView';
import { getSdkConversationObject, getSdkMessageObject } from '../../../../utils/chat/conversationsObjects';
import { List, Divider } from '@mui/material';
import moment from 'moment';
import { useLanguageContext } from '../../../../contexts/languageContext';

function getMessageTime(message) {
  const dateCreated = message.dateCreated;

  //TODO format time
  return dateCreated;
}

const MessageList = (props) => {
  const { messages, conversation, lastReadIndex } = props;
  const myRef = useRef(null);
  const [horizonMessageCount, setHorizonMessageCount] = useState(0);
  const [showHorizonIndex, setShowHorizonIndex] = useState(0);
  const [scrolledToHorizon, setScrollToHorizon] = useState(false);
  const { gettext } = useLanguageContext();

  const messagesLength = messages.length;
  let dayToShow = '';

  useEffect(() => {
    if (scrolledToHorizon || !myRef.current) {
      return;
    }
    myRef.current.scrollIntoView({
      behavior: 'smooth'
    });
    setScrollToHorizon(true);
  });

  useEffect(() => {
    if (lastReadIndex === -1 || horizonMessageCount) {
      return;
    }
    const showIndex = 0;
    getSdkConversationObject(conversation)
      .getUnreadMessagesCount()
      .then((count) => {
        setHorizonMessageCount(count ?? 0);
        setShowHorizonIndex(showIndex);
      });
  }, [messages, lastReadIndex]);

  if (messages === undefined) {
    return <div className='empty' />;
  }

  return (
    <>
      <List className={'messageArea'}>
        {props.participants &&
          messages.map((message, index) => {
            const attributes = message.attributes;
            let showNewDate = false;
            if (dayToShow !== moment.utc(message.dateCreated).local().format('LL').toString()) {
              showNewDate = true;
              dayToShow = moment.utc(message.dateCreated).local().format('LL').toString();
            }
            if (index === messages.length - 1) {
              setTimeout(() => {
                let el = document.getElementsByClassName('messageArea')[0];
                el.scrollTo(0, el.scrollHeight);
              }, 200);
            }

            return (
              <section key={message.sid + index}>
                {showNewDate && (
                  <p className={moment(message.dateCreated).isSame(moment(), 'day') ? 'divider' : 'divider filled'}>
                    <span>{moment(message.dateCreated).isSame(moment(), 'day') ? gettext('APP.COMMON.COMMON.TODAY') : dayToShow}</span>
                  </p>
                )}

                <MessageView
                  message={message}
                  conversation={conversation}
                  reactions={attributes['reactions']}
                  text={message.body ?? ''}
                  author={message.author ?? ''}
                  getStatus={getMessageStatus(message, props.participants)}
                  onDeleteMessage={async () => {
                    try {
                      await getSdkMessageObject(message).remove();
                    } catch {}
                  }}
                  lastMessageBottomPadding={index === messagesLength - 1 ? 16 : 0}
                  messageTime={getMessageTime(message)}
                  updateAttributes={(attribute) => {
                    console.log(message);
                    return getSdkMessageObject(message).updateAttributes({
                      ...attributes,
                      ...attribute
                    });
                  }}
                />
                {/*<Divider>FDSFDSfdsfsd</Divider>*/}
              </section>
            );
          })}
      </List>
    </>
  );
};

export default MessageList;
